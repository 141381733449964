import React, { useState } from 'react'
import styled from 'styled-components'
import { IDialogScreen } from '@monorepo/shared/common/models/dialogScreen'
import { useAppDispatch } from '../context/AppContext'
import colors from '@monorepo/shared/styles/colors'
import { mqFrom } from '@monorepo/shared/styles/responsive'
import { applyTextStyle } from '@monorepo/shared/styles/typography'

export interface IProps {
  content: IDialogScreen
}

const DialogScreen: React.FC<IProps> = ({ content }: IProps) => {
  const appDispatch = useAppDispatch()
  const [inProgress, setInProgress] = useState<boolean>(false)

  const onSubmit = async () => {
    setInProgress(true)
    try {
      await content.onSubmit()
    } finally {
      setInProgress(false)
    }
  }
  const onContinue = async () => {
    if (content.onDismiss) {
      setInProgress(true)
      try {
        await content.onDismiss()
      } finally {
        setInProgress(false)
      }
    } else {
      appDispatch({
        type: 'updateDialogScreen',
        payload: undefined,
      })
    }
  }

  return (
    <DialogContainer>
      <Content>
        <InnerContent>{content.content}</InnerContent>
        <SeparatingLine />
        <Actions>
          <SubmitLink onClick={onSubmit} disabled={inProgress}>
            {content.submitButtonText ? content.submitButtonText : 'Continue'}
          </SubmitLink>
          <CancelLink onClick={onContinue}>
            {content.dismissButtonText ? content.dismissButtonText : 'Annuleer'}
          </CancelLink>
        </Actions>
      </Content>
    </DialogContainer>
  )
}

const DialogContainer = styled.div`
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(15px);
  z-index: 1100;
  padding: 0 16px;
`

const Content = styled.div`
  position: relative;
  margin: 0 auto;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  max-height: 90vh;
  min-width: 280px;
  max-width: 430px;
  border-radius: 4px;
  background: ${colors.neutralWhite};
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 28px 23px;

  ${mqFrom.L`
    flex-direction: row;
    text-align: left;
    padding: 17px;
  `}
`

const InnerContent = styled.div`
  ${applyTextStyle('body', 'mobile')};
  flex-grow: 1;
  color: ${colors.primary2};

  ${mqFrom.L`
    max-width: 270px;
  `}
`

const SeparatingLine = styled.div`
  width: 50%;
  height: 1px;
  margin: 16px auto;
  background-color: ${colors.primary2};

  ${mqFrom.L`
    width: 1px;
    height: 28px;
    margin: auto 22px auto 22px;
  `};
`

const Actions = styled.div`
  display: flex;
  display: row;
  margin: 0 auto;
  flex-grow: 0;
  flex-direction: column;
`

const SubmitLink = styled.div<{ disabled?: boolean }>`
  ${applyTextStyle('link', 'mobile')}
  color: ${colors.primary2};
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};

  margin-bottom: 10px;
  margin-right: 0px;
`

const CancelLink = styled.div`
  ${applyTextStyle('link', 'mobile')}
  color: ${colors.primary2};
  cursor: pointer;
`

export default DialogScreen
