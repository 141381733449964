import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { GridCol, GridRow } from '@monorepo/shared/components/grid'
import { applyTextStyle } from '@monorepo/shared/styles/typography'
import { mqFrom, mqTo } from '@monorepo/shared/styles/responsive'
import Button from '@monorepo/shared/components/Button'
import colors from '@monorepo/shared/styles/colors'

interface IProps {
  className?: string
  items: React.ReactNode[]
  title: string
  buttonColor?: string
}

const HorizontalScrollList: React.FC<IProps> = ({
  className,
  items,
  title,
  buttonColor,
}) => {
  const scrollableContainerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>()

  const [scrollCounter, setScrollCounter] = useState<number>(0)

  const scrollStories = () => {
    if (scrollableContainerRef.current) {
      const scrollWidth = scrollableContainerRef.current.clientWidth

      scrollableContainerRef.current.scrollTo({
        left: scrollWidth * scrollCounter,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    scrollStories()
  }, [scrollCounter])

  useEffect(() => {
    window.addEventListener('resize', scrollStories)

    return () => {
      window.removeEventListener('resize', scrollStories)
    }
  })

  return (
    <Wrapper className={className}>
      <Heading>
        <Title>{title}</Title>
        <Arrows>
          <StyledButton
            disabled={scrollCounter === 0}
            icon="ArrowLeft"
            iconColor={buttonColor ?? colors.white}
            onClick={() => setScrollCounter(scrollCounter - 1)}
          ></StyledButton>
          <Button
            disabled={scrollCounter === Math.ceil(items.length / 4) - 1}
            icon="ArrowRight"
            iconColor={buttonColor ?? colors.white}
            onClick={() => setScrollCounter(scrollCounter + 1)}
          ></Button>
        </Arrows>
      </Heading>
      <StoriesOutside>
        <Stories ref={scrollableContainerRef}>
          {items.map((item, i) => (
            <SingleItem xs={2} m={3} l={3} key={i}>
              {item}
            </SingleItem>
          ))}
        </Stories>
      </StoriesOutside>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 36px;
  padding-left: 16px;
  position: relative;
  display: block;
  ${mqFrom.M`
    margin-top: 64px;
    padding: 0;
  `}
`

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
`

const Arrows = styled.div`
  display: none;
  ${mqFrom.M`
    display: flex;
  `}
`

const Title = styled.h3`
  ${applyTextStyle('heading3')}
  margin-bottom: 16px;
  ${mqFrom.M`
    margin-bottom: 44px;
  `}
`

const StoriesOutside = styled.div`
  width: 100%;
  // overflow: hidden;
  display: block;
  position: relative;
`

const Stories = styled(GridRow)`
  display: flex;
  flex-wrap: nowrap; /* [1] */
  overflow-x: auto; /* [2] */
  -webkit-overflow-scrolling: touch; /* [3] */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  padding-top: 20px;
  ${mqFrom.M`
    padding-top: 40px;
    margin-top: -40px;
    overflow: hidden;
  `}
`
const SingleItem = styled(GridCol)`
  ${mqTo.M`
    flex-basis: calc(50% - 18px);
    max-width: calc(50% - 18px);
  `}
`

const StyledButton = styled(Button)`
  margin-right: 16px;
`

export default HorizontalScrollList
