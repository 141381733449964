import React from 'react'
import colors from '@monorepo/shared/styles/colors'
import { IIconPropsMore as IProps } from './IIconProps'

const IconLogoFacebook = (props: IProps) => (
  <svg
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 20 20"
    fill={props.color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.65188 0C1.6289 0 0 1.6289 0 3.65187V16.3481C0 18.3711 1.6289 20 3.65188 20H10.5331V12.1812H8.46564V9.36624H10.5331V6.96125C10.5331 5.07175 11.7547 3.33687 14.5688 3.33687C15.7081 3.33687 16.5506 3.44625 16.5506 3.44625L16.4844 6.07501C16.4844 6.07501 15.6251 6.06689 14.6875 6.06689C13.6727 6.06689 13.51 6.53445 13.51 7.31064V9.36626H16.565L16.4319 12.1813H13.51V20H16.3481C18.3711 20 20 18.3711 20 16.3481V3.65189C20 1.62892 18.3711 2e-05 16.3481 2e-05H3.65186L3.65188 0Z"
      fill="white"
    />
  </svg>
)

IconLogoFacebook.defaultProps = {
  color: colors.white,
}

export default IconLogoFacebook
