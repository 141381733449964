import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import secondsToMMSS from '@monorepo/shared/common/helpers/timeFormat.helper'
import colors from '@monorepo/shared/styles/colors'
import { applyTextStyle } from '@monorepo/shared/styles/typography'
import { mqFrom } from '@monorepo/shared/styles/responsive'
import flameIcon from '../../../shared/images/flames.svg'

interface IProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>
  percentage: number
  duration: number
  currentTime: number
  hotPartFrom?: number
  hotPartTo?: number
  hideTumb?: boolean
}

const Slider: React.FC<IProps> = ({
  onChange,
  percentage,
  duration,
  currentTime,
  hotPartFrom,
  hotPartTo,
  hideTumb,
}) => {
  const [tooltipVisibility, setTooltipVisibility] = useState(0)

  // useEffect(() => {
  //   const thumbWidth = 16
  //   const centerThumb = (thumbWidth / 100) * percentage * -1
  //   // setMarginLeft(centerThumb)
  //   setPossition(percentage)
  // }, [percentage])

  const hotPartDuration =
    (hotPartFrom && hotPartTo && hotPartTo - hotPartFrom) ?? 0
  const hotPartStart =
    duration > 0 && hotPartFrom ? (hotPartFrom / duration) * 100 : 0
  const hotPartWidth = Math.min(
    duration > 0 ? (hotPartDuration / duration) * 100 : 0,
    100 - hotPartStart
  )

  const ChangeTooltipVisibility = () => {
    if (
      duration &&
      percentage >= hotPartStart &&
      percentage <= hotPartStart + hotPartWidth
    ) {
      setTooltipVisibility(1)
    } else {
      setTooltipVisibility(0)
    }
  }

  useEffect(() => {
    ChangeTooltipVisibility()
  }, [percentage])

  return (
    <SliderContainer>
      <Timer isOnTheLeft={false}>{secondsToMMSS(Math.round(duration))}</Timer>
      <ProgressBar />
      <ProgressBarCover style={{ width: `${percentage}%` }}>
        {!!tooltipVisibility && (
          <Tooltip>
            <Icon src={flameIcon} />
          </Tooltip>
        )}
      </ProgressBarCover>
      {!!(hotPartDuration && hotPartStart && hotPartWidth) && (
        <HotPart
          style={{ left: `${hotPartStart}%`, width: `${hotPartWidth}%` }}
        />
      )}
      {!hideTumb && (
        <Thumb style={{ left: `${percentage}%`, marginLeft: `-8px` }} />
      )}
      <Range value={percentage} type="range" onChange={onChange} />
      <Timer isOnTheLeft={true}>{secondsToMMSS(Math.round(currentTime))}</Timer>
    </SliderContainer>
  )
}

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  ${mqFrom.S`
    width: calc(100% - 78px);
    margin: 0 39px;
  `}
`
const Timer = styled.div<{ isOnTheLeft: boolean }>`
  ${applyTextStyle('smallText', 'mobile')}
  position: absolute;
  ${({ isOnTheLeft }) =>
    isOnTheLeft
      ? css`
          top: 25px;
          left: 0;
          ${mqFrom.S`
            top: 3px;
            left: -40px;
  `}
        `
      : css`
          top: 25px;
          right: 0;
          ${mqFrom.S`
            top: 3px;
            right: -40px;
  `}
        `}
`

const ProgressBar = styled.div`
  background-color: ${colors.neutralGray};
  width: 100%;
  height: 5px;
  display: block;
  position: absolute;
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
`

const ProgressBarCover = styled.div`
  background-color: ${colors.neutralWhite};
  height: 5px;
  display: block;
  position: absolute;
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  user-select: none;
  pointer-events: none;
`

const Thumb = styled.div`
  width: 16px;
  height: 16px;
  background: ${colors.neutralWhite};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  left: -8px;
  pointer-events: none;
  user-select: none;
  z-index: 3;
`

const Range = styled.input`
  -webkit-appearance: none;
  background-color: ${colors.neutralGray};
  height: 5px;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background-color: ${colors.primary3};
    border-radius: 50%;
    cursor: pointer;
  }
`

const HotPart = styled.div`
  background-color: ${colors.fdbkSpiciness};
  opacity: 0.4;
  height: 5px;
  display: block;
  position: absolute;
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  user-select: none;
  pointer-events: none;
`
const Tooltip = styled.div`
  width: 24px;
  height: 24px;
  background: ${colors.neutralWhite};
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  user-select: none;
  right: -12px;
  bottom: 15px;
  z-index: 3;
  text-align: center;
`

const Icon = styled.img`
  width: 12px;
  height: 16px;
  margin: auto;
`

export default Slider
