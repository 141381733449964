import React, { useState } from 'react'
import styled from 'styled-components'
import { applyTextStyle } from '@monorepo/shared/styles/typography'

interface IProps {
  className?: string
  dangerousInnerHTMLCaption: string
}

const ReadMore: React.FC<IProps> = ({
  className,
  dangerousInnerHTMLCaption,
}) => {
  const [isReadMore, setIsReadMore] = useState<boolean>(true)
  return (
    <Container className={className}>
      <TextContainer className={!isReadMore ? 'opened' : ''}>
        <Text
          dangerouslySetInnerHTML={{
            __html: dangerousInnerHTMLCaption,
          }}
        ></Text>
      </TextContainer>
      {dangerousInnerHTMLCaption.length > 200 && (
        <ReadMoreButton onClick={() => setIsReadMore(!isReadMore)}>
          {isReadMore ? 'TOON MEER' : 'TOON MINDER'}
        </ReadMoreButton>
      )}
    </Container>
  )
}

const Container = styled.div``

const Text = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  animation: close-effect 0.1s linear 0.1s forwards;
`

const TextContainer = styled.div`
  max-height: 148px;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

  &.opened {
    max-height: 100rem;
    transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);

    > * {
      animation: open-effect 0.1s linear 0s forwards;
    }
  }
`

const ReadMoreButton = styled.div<{ marginTop?: number }>`
  ${applyTextStyle('label')};
  cursor: pointer;
  text-align: right;
`

export default ReadMore
