import React from 'react'
import styled, { css } from 'styled-components'

// Styles
import colors from '@monorepo/shared/styles/colors'

// Components
import PlayerButton from './PlayerButton'
import { Icon } from '@monorepo/shared/components/icon/'
import { mqFrom } from '@monorepo/shared/styles/responsive'

interface IProps {
  isSingleStory: boolean
  play: (event?: React.MouseEvent) => void
  isPlaying: boolean
  moveTenSecondsBackward: (event?: React.MouseEvent) => void
  moveTenSecondsForward: (event?: React.MouseEvent) => void
  playPreviousEpisode?: (event?: React.MouseEvent) => void
  playNextEpisode?: (event?: React.MouseEvent) => void
  isPreviousEpisodeDisabled?: boolean
  isNextEpisodeDisabled?: boolean
  isFullScreen: boolean
}

const ControlPanel: React.FC<IProps> = ({
  isSingleStory,
  play,
  isPlaying,
  moveTenSecondsBackward,
  moveTenSecondsForward,
  playPreviousEpisode,
  playNextEpisode,
  isPreviousEpisodeDisabled,
  isNextEpisodeDisabled,
  isFullScreen,
}) => {
  return (
    <Container>
      {!isSingleStory && (
        <EpisodeSkipper disabled={isPreviousEpisodeDisabled}>
          <Icon
            type="PreviousEpisode"
            color={colors.neutralWhite}
            size={15}
            onClick={playPreviousEpisode}
          />
        </EpisodeSkipper>
      )}
      <TimeSkipper isOnTheLeft={true} onClick={moveTenSecondsBackward}>
        <Icon type="FastBackward" color={colors.neutralWhite} size={32} />
      </TimeSkipper>
      <StyledPlayerButton
        variant={isPlaying ? 'Pause' : 'Play'}
        onClick={play}
        size={isFullScreen ? 'medium' : 'small'}
      />
      <TimeSkipper isOnTheLeft={false} onClick={moveTenSecondsForward}>
        <Icon type="FastForward" color={colors.neutralWhite} size={32} />
      </TimeSkipper>
      {!isSingleStory && (
        <EpisodeSkipper disabled={isNextEpisodeDisabled}>
          <Icon
            type="NextEpisode"
            color={colors.neutralWhite}
            size={15}
            onClick={playNextEpisode}
          />
        </EpisodeSkipper>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
`

const TimeSkipper = styled.div<{ isOnTheLeft: boolean }>`
  position: relative;
  width: 32px;
  height: 32px;
  cursor: pointer;
  ${({ isOnTheLeft }) =>
    isOnTheLeft
      ? css`
          margin-left: 40px;
          ${mqFrom.S`
            margin-left: 55px;
          `}
        `
      : css`
          margin-right: 40px;
          ${mqFrom.S`
            margin-right: 55px;
          `}
        `}
`

const EpisodeSkipper = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  ${({ disabled }) => {
    if (disabled === true) {
      return css`
        opacity: 0.2;
        pointer-events: none;
      `
    }
  }}
`

const StyledPlayerButton = styled(PlayerButton)`
  margin: 0 20px;
  ${mqFrom.S`
    margin: 0 25px;
  `}
`

export default ControlPanel
