// tslint:disable: interface-over-type-literal */
import { createContext, useContext, useEffect, useState } from 'react'
import React, { useReducer } from 'react'
import PrismicLoader from './prismicLoader'

import Player from '../components/Player'
import { IPlayer } from '@monorepo/shared/common/models/player'
import styled from 'styled-components'

// Types
import GlobalStyles from '@monorepo/shared/styles'
import colors from '@monorepo/shared/styles/colors'
import OptionsCard from '../components/cards/OptionsCard'
import { IAuthUser } from '../common/models/user'
import { Helmet } from 'react-helmet'
import {
  deleteAuthCookie,
  getAuthCookie,
  getCookie,
} from '@monorepo/shared/utils/cookie.helper'
import { IDialogScreen } from '@monorepo/shared/common/models/dialogScreen'
import DialogScreen from '../components/DialogScreen'
import { useStaticQuery, graphql } from 'gatsby'
import { globalHistory } from '@reach/router'
import { navigate } from 'gatsby-link'
import {
  initAmplitude,
  sendAmplitudeData,
  setAmplitudeUserId,
} from '@monorepo/shared/utils/amplitude.helper'

// Utils
import '@monorepo/shared/styles/global.css'
import { isUserPendingFinalization } from '../utils/user.helper'
import getUserService from './services/getUser.service'
import { handleSocialMediaTracking } from '@monorepo/shared/common/helpers/socialMedia.helper'
import { parseJwt } from '@monorepo/shared/common/helpers/jwt.helper'

export interface ThemeType {
  bodyBackground: string
  isFooterTransparent: boolean
}

const theme: ThemeType = {
  bodyBackground: colors.primary2,
  isFooterTransparent: false,
}

export interface IOptionsPopup {
  coordinates: {
    x: number
    y: number
  }
}

// Types
export type State = {
  theme: ThemeType
  player?: IPlayer
  user?: IAuthUser
  userAuthToken?: string
  dialogScreen?: IDialogScreen
  optionsPopup?: IOptionsPopup
  processingModalOpen: boolean
  hasLoggedIn: boolean
}

export const DEFAULT_APP_STATE: State = {
  theme,
  player: undefined,
  userAuthToken: getAuthCookie() ?? undefined,
  dialogScreen: undefined,
  processingModalOpen: false,
  user: undefined,
  hasLoggedIn: false,
}

export type updatePlayerAction = {
  type: 'updatePlayer'
  payload?: IPlayer
}

export type updateUserAction = {
  type: 'updateUser'
  payload?: IAuthUser
}
export type updateUserTokenAction = {
  type: 'updateUserToken'
  payload?: string
}

export type updateDialogScreenAction = {
  type: 'updateDialogScreen'
  payload?: IDialogScreen
}

export type updateOptionsPopupAction = {
  type: 'updateOptionsPopup'
  payload?: IOptionsPopup
}

export type updateProcessingModalOpenAction = {
  type: 'updateProcessingModalOpen'
  payload: boolean
}

export type updateUserAndTokenAction = {
  type: 'updateUserAndToken'
  payload: Pick<State, 'user' | 'userAuthToken'>
}

export type Action =
  | updatePlayerAction
  | updateUserAction
  | updateUserTokenAction
  | updateDialogScreenAction
  | updateOptionsPopupAction
  | updateProcessingModalOpenAction
  | updateUserAndTokenAction

export type Dispatch = (action: Action) => void

// Contexts
export const AppStateCtx = createContext<State>({ ...DEFAULT_APP_STATE })

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AppDispatchCtx = createContext<Dispatch>((_: Action) => {
  return
})

// Reducer
export const appReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'updatePlayer': {
      return {
        ...state,
        player: action.payload,
      }
    }
    case 'updateUserToken': {
      return {
        ...state,
        userAuthToken: action.payload,
      }
    }
    case 'updateUser': {
      return {
        ...state,
        user: action.payload,
      }
    }
    case 'updateDialogScreen': {
      return {
        ...state,
        dialogScreen: action.payload,
      }
    }
    case 'updateOptionsPopup': {
      return {
        ...state,
        optionsPopup: action.payload,
      }
    }
    case 'updateProcessingModalOpen': {
      return {
        ...state,
        processingModalOpen: action.payload,
      }
    }
    case 'updateUserAndToken': {
      return {
        ...state,
        user: action.payload.user,
        userAuthToken: action.payload.userAuthToken,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`)
    }
  }
}

// Hooks
export const useAppState = () => {
  const context = useContext(AppStateCtx)

  if (!context) {
    throw new Error('useAppState must be used within a AppProvider')
  }

  return context
}

export const useAppDispatch = () => {
  const context = useContext(AppDispatchCtx)

  if (!context) {
    throw new Error('useAppDispatch must be used within a AppProvider')
  }

  return context
}

const routesForPeopleInProgress = [
  '/subscribe',
  '/subscription',
  '/profile',
  '/select-plan',
  '/login',
  '/result',
]
// const routesForGuests = ['/subscribe', '/subscription', '/select-plan']
// const routesForUsersOnly = ['/library', '/profile']
const noPlayerRoutes = ['/subscription', '/subscribe', '/select-plan']

export const ContextTemplate: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, { ...DEFAULT_APP_STATE })
  const [
    userNotifiedAboutFinalizeSubscription,
    setUserNotifiedAboutFinalizeSubscription,
  ] = useState<boolean>(false)

  const onLogout = () => {
    dispatch({
      type: 'updateUserToken',
      payload: undefined,
    })
    dispatch({
      type: 'updateUser',
      payload: undefined,
    })
  }
  useEffect(() => {
    document.addEventListener('logout', onLogout)
    return () => {
      document.removeEventListener('logout', onLogout)
    }
  })

  const routeGuard = (pathname: string) => {
    if (state.userAuthToken && state.user) {
      // If user has not completed their setup, show warning on specific pages
      if (
        !state.user.data.completed &&
        !state.user.data.setupInProgress &&
        !state.user.subscription
      ) {
        if (
          !userNotifiedAboutFinalizeSubscription &&
          !routesForPeopleInProgress.some((path) => pathname.startsWith(path))
        ) {
          setUserNotifiedAboutFinalizeSubscription(true)
          dispatch({
            type: 'updateDialogScreen',
            payload: {
              content:
                'Je hebt nog geen abonnement. Wil je er nu eentje kiezen? Je kunt op ieder gewenst moment opzeggen in je accountpagina.',
              submitButtonText: 'Ja',
              dismissButtonText: 'Nee',
              onSubmit: () => {
                if (state.user?.data.selectedPlan) {
                  navigate(
                    `/subscribe/${state.user?.data.selectedPlan}/bank-details`
                  )
                } else {
                  navigate(`/select-plan`)
                }
                dispatch({
                  type: 'updateDialogScreen',
                  payload: undefined,
                })
              },
              onDismiss: () => {
                dispatch({
                  type: 'updateDialogScreen',
                  payload: undefined,
                })
              },
            },
          })
        }
      }
    }
    if (
      state.player &&
      noPlayerRoutes.some((path) => pathname.startsWith(path))
    ) {
      dispatch({
        type: 'updatePlayer',
        payload: undefined,
      })
    }
  }

  useEffect(() => {
    const cookie = getCookie('CookieConsent')
    if (cookie === 'true' || state.userAuthToken) {
      const userData = parseJwt(state.userAuthToken || '')
      initAmplitude(userData?.stripeId)
      if (userData?.stripeId) {
        setAmplitudeUserId(userData?.stripeId)
      }
    }
    // Send amplitude event
    sendAmplitudeData('PageViewed', {
      pageName: globalHistory.location.pathname,
    })
  }, [state.userAuthToken])

  useEffect(() => {
    routeGuard(globalHistory.location.pathname ?? '/')
  }, [state.userAuthToken, state.user])

  useEffect(() => {
    handleSocialMediaTracking()
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    ;(async () => {
      if (state.userAuthToken && !state.user) {
        try {
          const user = await getUserService(state.userAuthToken)
          if (user) {
            dispatch({
              type: 'updateUserAndToken',
              payload: user,
            })
          }
        } catch (e) {
          deleteAuthCookie()
        }
      }
    })()
  }, [state.userAuthToken, state.user])

  useEffect(() => {
    if (
      state.user &&
      state.userAuthToken &&
      isUserPendingFinalization(state.user)
    ) {
      const refreshUserDetails = async () => {
        try {
          const user = await getUserService(state.userAuthToken ?? '')
          if (user) {
            dispatch({
              type: 'updateUserAndToken',
              payload: user,
            })
          }
        } catch (e) {
          console.info(e)
        }
      }
      const refresh = setInterval(refreshUserDetails, 5000)

      return () => {
        clearInterval(refresh)
      }
    }
  }, [state.user])

  useEffect(() => {
    return globalHistory.listen(({ location }) => {
      routeGuard(location.pathname ?? '/')
    })
  }, [
    state.player,
    state.userAuthToken,
    state.user,
    userNotifiedAboutFinalizeSubscription,
  ])

  useStaticQuery(graphql`
    query loadFooterImagesFromFooterApp {
      prismicWebappPage {
        data {
          footer_desktop {
            gatsbyImageData(placeholder: BLURRED, backgroundColor: "gray")
          }
          white_footer_desktop {
            gatsbyImageData(placeholder: BLURRED, backgroundColor: "gray")
          }
          white_footer_mobile {
            gatsbyImageData(placeholder: BLURRED, backgroundColor: "gray")
          }
          footer_mobile {
            gatsbyImageData(placeholder: BLURRED, backgroundColor: "gray")
          }
        }
      }
    }
  `)

  return (
    <AppStateCtx.Provider value={state}>
      <AppDispatchCtx.Provider value={dispatch}>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin={'true'}
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap"
            rel="stylesheet"
          />
          <link rel="stylesheet" type="text/css" href="/fonts/love/love.css" />
          <link
            rel="stylesheet"
            type="text/css"
            href="/fonts/sf-pro-display/sfpro.css"
          />
          <script type="text/javascript" src="/js/app.js"></script>
        </Helmet>
        <GlobalStyles />
        <PrismicLoader />
        {state.processingModalOpen && (
          <LoadingPopup
            className={state.processingModalOpen ? 'shown' : 'hidden'}
          >
            Een momentje aub...
          </LoadingPopup>
        )}
        {state.optionsPopup && <OptionsCard options={{ type: 'story' }} />}
        {state.player && <Player content={state.player} />}
        {state.dialogScreen && <DialogScreen content={state.dialogScreen} />}
        <>{children}</>
        {/* <Footer mode={'app'} data={data} /> */}
      </AppDispatchCtx.Provider>
    </AppStateCtx.Provider>
  )
}

const LoadingPopup = styled.div`
  position: fixed;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
  line-height: 100vh;
  color: black;
  font-size: 22px;
  display: none;

  &.shown {
    display: block;
  }
`
