import React from 'react'
import colors from '@monorepo/shared/styles/colors'
import { IIconPropsMore as IProps } from './IIconProps'

const IconLogoLetter = (props: IProps) => (
  <svg
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 84 79"
  >
    <path
      d="M35.1622 42.2858C34.5906 40.3072 33.2686 35.5098 32.5485 32.8783C39.7602 30.6567 45.9068 23.6934 45.9068 15.212C45.9068 6.27253 39.8987 0 30.2998 0H9.59885L0 54.8866H9.36467L12.9529 34.1864H17.9462C17.9462 34.1864 20.0689 34.237 23.2694 34.0675C23.7781 35.8767 25.5583 42.2251 26.1853 44.5808C26.4799 45.6916 29.1063 55.3926 36.9651 64.2106C43.3107 71.3333 54.1308 79 71.2838 79C74.7159 79 78.4024 78.6938 82.3582 78.0132L82.3532 77.9828L82.4111 77.993L84 68.3172L80.7693 68.846C43.8168 75.1844 35.4543 43.2979 35.1622 42.2858ZM14.5166 25.2521L17.4048 8.94449H27.8044C33.11 8.94449 36.2324 11.0623 36.2324 15.217C36.2324 21.4896 31.6294 25.2521 24.9187 25.2521H14.5166Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="42.0015"
        y1="88.0162"
        x2="42.0015"
        y2="20.8792"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1067" stopColor="white" stopOpacity="0" />
        <stop offset="0.1117" stopColor="white" stopOpacity="0.01" />
        <stop offset="0.8888" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
)
IconLogoLetter.defaultProps = {
  color: colors.white,
}

export default IconLogoLetter
