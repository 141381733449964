import React from 'react'
import colors from '@monorepo/shared/styles/colors'
import { IIconPropsMore as IProps } from './IIconProps'

const IconFlame = (props: IProps) => (
  <svg
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 9 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.199608 7.86952C0.199608 5.67059 1.51551 4.18182 1.59598 4.03422C1.68733 4.50909 1.66993 5.38182 2.83793 6.05348C2.82488 3.71765 3.37299 1.19786 5.40013 0C5.41318 0.162567 5.063 1.52941 6.15487 2.94118C6.57247 3.48021 6.94223 4.05561 7.35984 4.59679C7.97102 5.39037 8.59961 6.3508 8.59961 7.86952C8.59961 10.1497 6.7182 12 4.39961 12C2.08102 12 0.199608 10.1497 0.199608 7.86952Z"
      fill={props.color}
    />
  </svg>
)
IconFlame.defaultProps = {
  color: colors.white,
}

export default IconFlame
