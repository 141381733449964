import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrowRight = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 16 18"
    fill="none"
  >
    <path
      d="M15.2727 4.5H12.1818V4.14C12.1818 1.854 10.3091 0 8 0C5.69091 0 3.81818 1.854 3.81818 4.14V4.5H0.727273C0.325 4.5 0 4.82175 0 5.22V17.28C0 17.6783 0.325 18 0.727273 18H15.2727C15.675 18 16 17.6783 16 17.28V5.22C16 4.82175 15.675 4.5 15.2727 4.5ZM5.45455 4.14C5.45455 2.74725 6.59318 1.62 8 1.62C9.40682 1.62 10.5455 2.74725 10.5455 4.14V4.5H5.45455V4.14ZM14.3636 16.38H1.63636V6.12H3.81818V8.1C3.81818 8.199 3.9 8.28 4 8.28H5.27273C5.37273 8.28 5.45455 8.199 5.45455 8.1V6.12H10.5455V8.1C10.5455 8.199 10.6273 8.28 10.7273 8.28H12C12.1 8.28 12.1818 8.199 12.1818 8.1V6.12H14.3636V16.38Z"
      fill={props.color}
    />
  </svg>
)

export default IconArrowRight
