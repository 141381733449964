import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrowRight = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 9 14"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.90669 5.62195C7.91356 5.62845 7.92036 5.63506 7.92708 5.64179L8.59383 6.30853C8.98435 6.69905 8.98435 7.33222 8.59383 7.72274L2.7247 13.5919C2.33417 13.9824 1.70101 13.9824 1.31048 13.5919L0.643742 12.9251C0.253218 12.5346 0.253218 11.9014 0.643743 11.5109L5.13874 7.01592L0.56386 2.44103C0.173336 2.05051 0.173336 1.41734 0.56386 1.02682L1.2306 0.360078C1.62113 -0.0304457 2.25429 -0.0304447 2.64482 0.360079L7.90669 5.62195Z"
      fill={props.color}
    />
  </svg>
)

export default IconArrowRight
