import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconPerson = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 2.85C10.26 2.85 8.85 4.26 8.85 6C8.85 7.74 10.26 9.15 12 9.15C13.74 9.15 15.15 7.74 15.15 6C15.15 4.26 13.74 2.85 12 2.85ZM12 16.35C7.545 16.35 2.85 18.54 2.85 19.5V21.15H21.15V19.5C21.15 18.54 16.455 16.35 12 16.35ZM12 0C15.315 0 18 2.685 18 6C18 9.315 15.315 12 12 12C8.685 12 6 9.315 6 6C6 2.685 8.685 0 12 0ZM12 13.5C16.005 13.5 24 15.51 24 19.5V24H0V19.5C0 15.51 7.995 13.5 12 13.5Z"
      fill={props.color}
    />
  </svg>
)

export default IconPerson
