import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const PrismicLoader: React.FC = () => {
  useStaticQuery(graphql`
    fragment FullAuthor on PrismicAuthor {
      slug: uid
      data {
        name {
          text
        }
        about {
          text
        }
        quote {
          text
        }
        picture {
          url
          gatsbyImageData(placeholder: BLURRED, backgroundColor: "gray")
        }
      }
    }
    fragment FullSeries on PrismicSeries {
      slug: uid
      tags
      data {
        metaDescription: meta_description
        available_during_trial
        level_of_intensity
        meta_description
        story_type
        show_tag_new_until
        title {
          text
        }
        description {
          html
        }
        cover {
          url
          gatsbyImageData(placeholder: BLURRED, backgroundColor: "gray")
        }
        clear_cover {
          gatsbyImageData(placeholder: BLURRED, backgroundColor: "gray")
        }
        episodes {
          episode {
            id
          }
        }
        authors {
          author {
            document {
              ... on PrismicAuthor {
                data {
                  name {
                    text
                  }
                }
                slug: uid
              }
            }
          }
        }
      }
    }
    fragment FullEpisode on PrismicEpisode {
      data {
        title {
          text
        }
        preview_sound_file {
          url
        }
        sound_file {
          url
        }
        total_length
        hot_part_start
        hot_part_end
      }
    }
    fragment FullEpisodeWithDescriptions on PrismicEpisode {
      ...FullEpisode
      data {
        description {
          html
          text
        }
        preview_story {
          html
        }
        story {
          html
        }
      }
    }
    fragment FullSeriesWithEpisode on PrismicSeries {
      ...FullSeries
      data {
        episodes {
          episode {
            document {
              ...FullEpisode
            }
          }
        }
      }
    }
    fragment FullSeriesWithEpisodeWithDescriptions on PrismicSeries {
      ...FullSeries
      data {
        episodes {
          episode {
            document {
              ...FullEpisodeWithDescriptions
            }
          }
        }
      }
    }
    query loadMainAppState333 {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return <></>
}

export default PrismicLoader
