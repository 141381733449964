import React from 'react'
import styled from 'styled-components'

import { IStory } from '@monorepo/shared/common/models/story'
import { applyTextStyle } from '@monorepo/shared/styles/typography'
import colors from '@monorepo/shared/styles/colors'
import { mqFrom } from '@monorepo/shared/styles/responsive'
import { Link } from 'gatsby'
import BgImage from '@monorepo/shared/components/BgImage'
import { getImage } from 'gatsby-plugin-image'

interface IProps {
  className?: string
  story: IStory
}

const StoryCard: React.FC<IProps> = ({ className, story }) => {
  const { data, tags, slug } = story

  const NewStoryHeader = () => {
    const timeElapsed = Date.now()
    if (timeElapsed < new Date(story.data.show_tag_new_until).getTime()) {
      return <NewStory>NIEUWE AFLEVERINGEN</NewStory>
    } else {
      return <></>
    }
  }

  return (
    <Card className={className} to={`/series/${slug}`}>
      <StoryImage>
        <BgImage
          image={getImage(
            data.clear_cover.gatsbyImageData ? data.clear_cover : data.cover
          )}
        ></BgImage>
        <NewStoryHeader />
        <EpisodeCounter>{data.episodes.length || 0}</EpisodeCounter>
      </StoryImage>
      {/* <StoryImage image={image}>
        <EpisodeCounter>{episodes?.length || 0}</EpisodeCounter>
      </StoryImage> */}
      <StoryTitle>{data.title.text}</StoryTitle>
      <StoryTags>
        {(tags ?? []).map((tag, i) => (
          <Tag key={`tag-link-card-${i}`}>{tag}</Tag>
        ))}
      </StoryTags>
    </Card>
  )
}

const Card = styled(Link)`
  position: relative;
  display: block;
`

const StoryImage = styled.div`
  position: relative;
  display: block;
  height: 0px;
  padding-bottom: 100%;
  margin-bottom: 7px;
`

const EpisodeCounter = styled.div`
  background: ${colors.whiteSemiTransparent};
  border: 1px solid ${colors.white};
  box-sizing: border-box;
  border-radius: 100%;
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.black};
  display: none;
  ${mqFrom.M`
    width: 40px;
    height: 40px;
  `}
`

const StoryTitle = styled.h4`
  ${applyTextStyle('heading4')}
  margin-bottom: 8px;
`

const StoryTags = styled.div`
  display: block;
  ${applyTextStyle('smallText')}
`
const Tag = styled.div`
  display: inline-block;
  padding-right: 6px;
  white-space: nowrap;
  position: relative;

  &:after {
    content: '·';
    position: relative;
    padding-left: 6px;
    color: ${colors.white};
  }

  &:last-child:after {
    display: none;
  }
`

const NewStory = styled.div`
  ${applyTextStyle('smallText')}
  letter-spacing: 0.21em !important;
  font-size: 7px !important;
  font-weight: 700 !important;
  position: absolute;
  overflow: visible;
  padding: 4px 6px;
  margin-left: auto;
  margin-right: auto;
  left: 50%;
  top: -13px;
  white-space: nowrap;
  transform: translate(-50%, 0);
  background-color: #ffffff;
  color: #000000;
  ${mqFrom.M`
    font-size: 10px !important;
  `}
`

export default StoryCard
