import { createAuthCookie } from '@monorepo/shared/utils/cookie.helper'
import { GET_USER } from '../../constants/api.constants'
import errorHandler from './errorHandler'
import { IAuthUser } from '../../common/models/user'
import { deleteAuthCookie } from '@monorepo/shared/utils/cookie.helper'

export default (token: string) =>
  fetch(GET_USER(), {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
    .then(errorHandler)
    .then(
      async (
        feedback: Response
      ): Promise<{ user: IAuthUser; userAuthToken: string } | undefined> => {
        const action = await feedback.json()
        if (action.userToken) {
          createAuthCookie(action.userToken)
        }
        if (action.authUser) {
          return {
            user: action.authUser,
            userAuthToken: action.userToken,
          }
        } else {
          deleteAuthCookie()
        }
      }
    )
