import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrowRight = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 9 14"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.09428 8.37743C1.08742 8.37094 1.08062 8.36433 1.07389 8.3576L0.407151 7.69086C0.0166265 7.30033 0.016627 6.66717 0.407151 6.27665L6.27628 0.407517C6.6668 0.016993 7.29997 0.0169929 7.69049 0.407517L8.35723 1.07426C8.74776 1.46478 8.74776 2.09795 8.35723 2.48847L3.86223 6.98347L8.43712 11.5584C8.82764 11.9489 8.82764 12.582 8.43712 12.9726L7.77038 13.6393C7.37985 14.0298 6.74669 14.0298 6.35616 13.6393L1.09428 8.37743Z"
      fill={props.color}
    />
  </svg>
)

export default IconArrowRight
