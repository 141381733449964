import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconEye = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 20 16"
    fill="none"
  >
    <path
      d="M18.6672 6.58202C19.1109 7.1625 19.1109 7.94616 18.6672 8.52571C17.2693 10.3505 13.9156 14.1077 10.0001 14.1077C6.08468 14.1077 2.73097 10.3505 1.33313 8.52571C1.11721 8.24776 1 7.90582 1 7.55386C1 7.2019 1.11721 6.85997 1.33313 6.58202C2.73097 4.75724 6.08468 1 10.0001 1C13.9156 1 17.2693 4.75724 18.6672 6.58202V6.58202Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0002 10.3627C11.5515 10.3627 12.809 9.10517 12.809 7.55392C12.809 6.00266 11.5515 4.74512 10.0002 4.74512C8.44895 4.74512 7.19141 6.00266 7.19141 7.55392C7.19141 9.10517 8.44895 10.3627 10.0002 10.3627Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="10" cy="8" r="3" fill="white" fillOpacity="0.2" />
  </svg>
)

export default IconEye
