// Global stylesheet
import { createGlobalStyle } from 'styled-components'
import colors from './colors'

// Styling

const GlobalStyle = createGlobalStyle`

  html, body {
    background: ${colors.primary2};
  }
`

export default GlobalStyle

// footer {
//   ${({ theme }) =>
//     css`
//       background: ${theme.footerBackground};
//       ${mqFrom.M`
//         background: ${theme.footerBackgroundDesktop};
//       `}
//     `}
// }
