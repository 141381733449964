import React from 'react'
import styled from 'styled-components'

// Styling
import { mqFrom } from '@monorepo/shared/styles/responsive'

interface IProps {
  className?: string
  children: React.ReactNode
}
const MobileContainer: React.FC<IProps> = ({ className, children }) => (
  <Wrapper className={className}>{children}</Wrapper>
)

const Wrapper = styled.div`
  padding: 0 16px;
  ${mqFrom.M`
    padding: 0;
  `}
`

export default MobileContainer
