import React from 'react'
import styled from 'styled-components'

// Styling
import { mqFrom } from '@monorepo/shared/styles/responsive'

interface IProps {
  className?: string
  children: React.ReactNode
  withMobilePadding?: boolean
}
const AppContainer: React.FC<IProps> = ({
  className,
  children,
  withMobilePadding,
}) => (
  <Wrapper
    className={`app-container ${className ?? ''}`}
    withMobilePadding={withMobilePadding}
  >
    {children}
  </Wrapper>
)

const Wrapper = styled.div<{ withMobilePadding?: boolean }>`
  position: relative;
  padding: ${({ withMobilePadding }) => (withMobilePadding ? '0 16px' : '0')};
  margin-top: ${({ withMobilePadding }) =>
    withMobilePadding ? '140px !important;' : '0'};
  ${mqFrom.M`
    margin: 0 auto;
    padding: 0 16px;
    max-width: 890px;
  `}
  ${mqFrom.XL`
    padding: 0;
  `}
`

export default AppContainer
