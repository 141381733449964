import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { GridCol, GridRow } from '@monorepo/shared/components/grid'
import { applyTextStyle } from '@monorepo/shared/styles/typography'
import { mqFrom, mqTo } from '@monorepo/shared/styles/responsive'
import { Icon } from '@monorepo/shared/components/icon/'
import AppContainer from '@monorepo/shared/components/AppContainer'
import Button from '@monorepo/shared/components/Button'
import BgImage from '@monorepo/shared/components/BgImage'
import Input from '@monorepo/shared/components/forms/Input'
import { InputVariant } from '@monorepo/shared/components/forms/Input'
import { globalHistory } from '@reach/router'
import { useEffect } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import colors from '@monorepo/shared/styles/colors'
import { getAuthCookie } from '@monorepo/shared/utils/cookie.helper'
import { validateEmail } from '@monorepo/shared/utils/validators.helper'
import { getImage } from 'gatsby-plugin-image'
import { openFeedbackPopup } from '@monorepo/shared/utils/feedback.helper'

const GATSBY_WEBSITE_URL = process.env.GATSBY_WEBSITE_URL
const GATSBY_APP_URL = process.env.GATSBY_ROUZE_APP_URL

const whiteWebsiteRoutes = ['/blog', '/faq', '/privacy', '/terms', '/support']
interface IProps {
  className?: string
  mode: 'website' | 'app'
}

interface FooterBgState {
  desktop?: any
  mobile?: any
  mobileColor?: string
}

const Footer: React.FC<IProps> = ({ mode }) => {
  const [newsletterEmail, setNewsletterEmail] = useState<string>('')
  const [newsletterStatusText, setNewsletterStatusText] = useState<string>('')
  const [newsletterInProgress, setNewsletterInProgress] =
    useState<boolean>(false)

  const isEmailValid = validateEmail(newsletterEmail)

  const isAuthenticated = getAuthCookie() ? true : false

  const data = useStaticQuery(graphql`
    query loadMainAppState13233 {
      prismicWebappPage {
        data {
          how_do_you_feel_desktop {
            gatsbyImageData
          }
          how_do_you_feel_mobile {
            gatsbyImageData
          }
          footer_desktop {
            gatsbyImageData(placeholder: BLURRED, backgroundColor: "gray")
          }
          white_footer_desktop {
            gatsbyImageData(placeholder: BLURRED, backgroundColor: "gray")
          }
          white_footer_mobile {
            gatsbyImageData(placeholder: BLURRED, backgroundColor: "gray")
          }
          footer_mobile {
            gatsbyImageData(placeholder: BLURRED, backgroundColor: "gray")
          }
        }
      }
    }
  `)

  const getCorrectBackground = (pathname: string): FooterBgState => {
    if (mode === 'website') {
      if (whiteWebsiteRoutes.some((path) => pathname.startsWith(path))) {
        return {
          desktop: data.prismicWebappPage.data.white_footer_desktop,
          mobile: data.prismicWebappPage.data.white_footer_mobile,
        }
      }
    }
    if (mode === 'app') {
      if (pathname.startsWith('/series')) {
        return {
          desktop: undefined,
          mobile: undefined,
          mobileColor: colors.primary1,
        }
      }
      if (pathname.startsWith('/author')) {
        return {
          desktop: undefined,
          mobile: undefined,
        }
      }
    }

    return {
      desktop: data.prismicWebappPage.data.footer_desktop,
      mobile: data.prismicWebappPage.data.footer_mobile,
    }
  }

  const [background, setBackground] = useState<FooterBgState>(
    getCorrectBackground(globalHistory.location.pathname ?? '/')
  )

  useEffect(() => {
    return globalHistory.listen(({ location }) => {
      setBackground(getCorrectBackground(location.pathname ?? '/'))
    })
  }, [])

  const getWebsiteLink = (url: string, text: string) => {
    return mode === 'website' ? (
      <Link to={url}>{text}</Link>
    ) : (
      <a href={`${GATSBY_WEBSITE_URL}${url}`}>{text}</a>
    )
  }

  const getAppLink = (url: string, text: string) => {
    return mode === 'app' ? (
      <Link to={url}>{text}</Link>
    ) : (
      <a href={`${GATSBY_APP_URL}${url}`}>{text}</a>
    )
  }

  const onSubmit = async () => {
    const options = {
      email: newsletterEmail,
    }

    setNewsletterInProgress(true)
    setNewsletterStatusText('')

    try {
      const followRequest = await fetch(
        `${process.env.GATSBY_API_URL}/newsletter/subscribe`,
        {
          method: 'POST',
          body: JSON.stringify(options),
        }
      )

      const response = await followRequest.json()

      if (followRequest.ok) {
        setNewsletterEmail('')
        setNewsletterStatusText(response.message ?? 'Success!')
      } else {
        setNewsletterStatusText(
          response.externalMessage.message ??
            'Something wrong happened, please try again later'
        )
      }

      // this.setState({ formSubmitted: true })
    } catch (err) {
      console.error(`Error fetching server login:`, err)
    } finally {
      setNewsletterInProgress(false)
    }
  }

  return (
    <FooterWrapper mobileColor={background.mobileColor}>
      <StyledBgImage
        image={getImage(background.desktop)}
        position="center top"
        height="100%"
        className="desktop-image"
      />

      <StyledMobileBgImage
        image={getImage(background.mobile)}
        position="center top"
        className="mobile-image"
      />

      <StyledAppContainer>
        <GridRow>
          <LogoCol xs={4} m={2}>
            <Icon
              type="LogoLetter"
              width={84}
              height={84}
              color={colors.black}
            />
          </LogoCol>
          <LogoCol xs={4} m={6}>
            <NewsletterTitle>Nieuwsbrief</NewsletterTitle>
            <NewsletterDetails>
              Blijf op de hoogte van de laatste verhalen, meld je aan voor de
              nieuwsbrief!
            </NewsletterDetails>
            <SubscribeForm>
              <StyledInput
                variant={InputVariant.Secondary}
                name="confirmEmail"
                placeholder="bevestig mailadres"
                type="email"
                onChange={(e) => setNewsletterEmail(e.target.value)}
              />
              <SubscribeButton
                caption="Abonneer"
                variant="secondary"
                disabled={!isEmailValid || newsletterInProgress}
                onClick={onSubmit}
              />
            </SubscribeForm>
            {newsletterStatusText && (
              <NewsletterMessage> {newsletterStatusText} </NewsletterMessage>
            )}
            <SocialLogos>
              <a
                href="https://www.instagram.com/rouze.me/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  type="Instagram"
                  width={24}
                  height={24}
                  color={colors.white}
                />
              </a>
              <a
                href="https://www.facebook.com/Rouze-101799007902279/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon type="Facebook" width={24} height={24} />
              </a>
              <a
                href="mailto:info@rouze.me"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon type="Mail" width={24} height={24} color={colors.white} />
              </a>
            </SocialLogos>
          </LogoCol>
          <LogoCol xs={2} m={2}>
            <NavLinks>
              <NavLink>{getWebsiteLink('/over-ons', 'Over ons')}</NavLink>
              <br />
              <NavLink>{getAppLink('/subscribe', 'Abonneer')}</NavLink>
              <br />
              <NavLink>{getWebsiteLink('/blog', 'Blog')}</NavLink>
              <br />
              <NavLink>{getWebsiteLink('/cadeau', 'Cadeau')}</NavLink>
              <br />
              <NavLink>{getWebsiteLink('/faq', 'FAQ')}</NavLink>
              <br />
              <NavLink>{getWebsiteLink('/support', 'Support')}</NavLink>
              <br />
            </NavLinks>
          </LogoCol>
          <LogoCol xs={2} m={2}>
            <NavLinks>
              <NavLink>{getAppLink('/authors', 'Schrijvers')}</NavLink>
              <br />
              {isAuthenticated && (
                <>
                  <NavLink>{getAppLink('/library', 'Bibliotheek')}</NavLink>
                  <br />
                </>
              )}
              <NavLink>{getAppLink('/search', 'Zoeken')}</NavLink>
              <br />
              <NavLink onClick={openFeedbackPopup}>Feedback</NavLink>
              <br />
            </NavLinks>
          </LogoCol>
        </GridRow>
        <BottomRow>
          <LogoCol xs={4} m={6} l={5}>
            <Copyright>
              {`© ${new Date().getFullYear()} Rouze B.V. - Alle rechten voorbehouden`}
            </Copyright>
          </LogoCol>
          <LogoCol xs={4} m={3} l={3}>
            <FootLink>
              {getWebsiteLink('/privacy', 'Privacy verklaring')}
            </FootLink>
          </LogoCol>
          <LogoCol xs={4} m={3} l={4}>
            <FootLinkLeft>
              {getWebsiteLink('/terms', 'Algemene voorwaarden')}
            </FootLinkLeft>
          </LogoCol>
        </BottomRow>
      </StyledAppContainer>
    </FooterWrapper>
  )
}

const StyledMobileBgImage = styled(BgImage)`
  z-index: -1;
  display: block;
  ${mqFrom.M`
    display: none;
  `}
`

const StyledBgImage = styled(BgImage)`
  z-index: -1;
  display: none;
  ${mqFrom.M`
    display: block;
  `}
`

const StyledAppContainer = styled(AppContainer)`
  z-index: 1;
`

// important - style is in global.css to optimize performance
const FooterWrapper = styled.footer<{ mobileColor?: string }>`
  ${({ mobileColor }) =>
    mobileColor &&
    css`
      ${mqTo.M`
        background-color: ${mobileColor};
      `}
    `}
`

const LogoCol = styled(GridCol)`
  text-align: center;
  ${mqFrom.M`
    text-align: left;
  `}
`

const NewsletterTitle = styled.h3`
  margin: 59px 0 8px 0;
  ${applyTextStyle('heading3')};
`

const NewsletterDetails = styled.div`
  ${applyTextStyle('smallText')};
  max-width: 70%;
  margin: 0 auto;
  ${mqFrom.M`
    margin: 0;
  `}
`

const SubscribeForm = styled.div`
  display: flex;
  margin-top: 28px;
  width: 100%;
  flex-direction: column;
  ${mqFrom.M`
    flex-direction: row;
    margin-top: 20px;
  `}
`

const SubscribeButton = styled(Button)`
  margin: 0 auto;
  margin-top: 18px;
  ${mqFrom.M`
    margin: 0;
  `}
`

const StyledInput = styled(Input)`
  margin: 0 auto;
  max-width: 80vw;
  flex-basis: 85%;
  width: 80vw;
  ${mqFrom.M`
    width: 100%;
    flex-basis: 50%;
    max-width: 100%;
    margin: 0;
    margin-right: 24px;
  `}
`

const SocialLogos = styled.div`
  display: flex;
  margin-top: 18px;
  justify-content: center;
  > * {
    margin-right: 12px;
  }
  ${mqFrom.M`
    justify-content: left;
  `}
`

const NavLinks = styled.div`
  margin-top: 32px;
  text-align: left;
  display: inline-block;
`

const NavLink = styled.span`
  ${applyTextStyle('heading3', 'mobile')};
  margin-bottom: 14px;
  cursor: pointer;
  display: inline-block;
`

const Copyright = styled.div`
  ${applyTextStyle('smallText', 'mobile')};

  ${mqFrom.M`
    text-align: right;
    width: 100%;
  `}
`

const FootLink = styled.div`
  ${applyTextStyle('link', 'mobile')};

  ${mqFrom.M`
    text-align: right;
  `}
`

const FootLinkLeft = styled.div`
  ${applyTextStyle('link', 'mobile')};

  ${mqFrom.M`
    text-align: left;
  `}
`

const BottomRow = styled(GridRow)`
  margin-top: 64px;
  margin-bottom: 40px;
  align-items: center;
  * {
    margin-bottom: 16px;
  }

  ${mqFrom.M`
    text-align: left;
    margin-bottom: 60px;
    * {
      margin-bottom: 24px;
    }
  `}
`

const NewsletterMessage = styled.div`
  ${applyTextStyle('smallText', 'mobile')};
  margin-top: 16px;
  margin-bottom: 8px;
`

export default Footer
