import React from 'react'
import styled from 'styled-components'

// Styling
import { mqFrom } from '@monorepo/shared/styles/responsive'

interface IProps {
  className?: string
  children: React.ReactNode
}
const AppPage: React.FC<IProps> = ({ className, children }) => (
  <Wrapper className={`app-page ${className ?? ''}`}>{children}</Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  padding-bottom: 120px;
  min-height: 100vh;

  ${mqFrom.M`
    padding-top: 140px;
    padding-bottom: 920px;
  `}
`

export default AppPage
