import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconClose = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2553 4.74549C21.6671 4.33378 21.7634 3.76258 21.4705 3.46969L20.4098 2.40903C20.1169 2.11614 19.5457 2.21246 19.134 2.62417L11.8794 9.87876L4.74616 2.7455C4.33445 2.33379 3.76325 2.23746 3.47036 2.53036L2.4097 3.59102C2.11681 3.88391 2.21313 4.45511 2.62484 4.86682L9.75811 12.0001L2.73363 19.0246C2.32191 19.4363 2.22559 20.0075 2.51849 20.3004L3.57915 21.361C3.87204 21.6539 4.44323 21.5576 4.85495 21.1459L11.8794 14.1214L19.0252 21.2672C19.4369 21.6789 20.0081 21.7752 20.301 21.4824L21.3617 20.4217C21.6546 20.1288 21.5583 19.5576 21.1466 19.1459L14.0007 12.0001L21.2553 4.74549Z"
      fill={props.color}
    />
  </svg>
)

export default IconClose
