import { IEpisode, IEpisodeWithDescriptions } from './episode'
interface IStoryData {
  metaDescription?: string
  title: {
    text: string
  }
  cover: any
  clear_cover: any
  story?: string
  description: { html: string }
  level_of_intensity?: number
  episodes: { id: any }[]
  authors: {
    author: {
      document?: {
        slug: string
        data: {
          name: {
            text: string
          }
        }
      }
    }
  }[]
  show_tag_new_until: string
  story_type: StoryType
  available_during_trial?: boolean
}

interface IStoryDataWithEpisode extends IStoryData {
  episodes: IEpisode[]
}
export interface IStory {
  slug: string
  tags: string[]
  data: IStoryData
}

export interface IStoryWithEpisodes extends IStory {
  data: IStoryDataWithEpisode
}
interface IStoryDataWithEpisodeWithDescriptions extends IStoryData {
  episodes: IEpisodeWithDescriptions[]
}

export interface IStoryWithEpisodesWithDescriptions extends IStory {
  data: IStoryDataWithEpisodeWithDescriptions
}

export function isStoryWithEpisodesWithDescriptions(
  story: IStoryWithEpisodes | IStoryWithEpisodesWithDescriptions
): story is IStoryWithEpisodesWithDescriptions {
  return (
    (story as IStoryWithEpisodesWithDescriptions).data.episodes[0]?.story
      ?.html !== undefined
  )
}

export enum StoryType {
  READ = 'Read',
  LISTEN = 'Listen',
  LISTEN_AND_READ = 'Listen&Read',
}
