import React, { useEffect, useState } from 'react'
import { graphql, navigate, Link } from 'gatsby'
import styled from 'styled-components'
import {
  IStory,
  IStoryWithEpisodesWithDescriptions,
  StoryType,
} from '@monorepo/shared/common/models/story'
import AppContainer from '@monorepo/shared/components/AppContainer'
import { useAppDispatch, useAppState } from '../context/AppContext'
import colors from '@monorepo/shared/styles/colors'
import { mqFrom } from '@monorepo/shared/styles/responsive'
import AppPage from '@monorepo/shared/components/AppPage'
import Navigation from '@monorepo/shared/components/Navigation'

import { Icon } from '@monorepo/shared/components/icon/'
import PlayerButton, { PlayerButtonVariant } from '../components/PlayerButton'
import { applyTextStyle } from '@monorepo/shared/styles/typography'
import { GridCol, GridRow } from '@monorepo/shared/components/grid'
import Button from '@monorepo/shared/components/Button'
import SerieCard from '../components/cards/SerieCard'
import MobileContainer from '../components/MobileContainer'
import BgImage from '@monorepo/shared/components/BgImage'
import StoriesList from '../components/StoriesList'
import Seo from '@monorepo/shared/components/Seo'
import ReadMore from '../components/ReadMore'
import likeDislikeService from '../context/services/likeDislike.service'
import { getStoryLength } from '@monorepo/shared/utils/dates.helper'
import { getImage } from 'gatsby-plugin-image'
import { parseTag } from '@monorepo/shared/utils/tags.helper'
import { notEmpty } from '@monorepo/shared/utils/guards.helper'
interface IProps {
  pageContext: { slug: string; episodeSlugs: [string]; episodeSlug?: string }
  data: any
}

const SingleStoryPage: React.FC<IProps> = ({ pageContext, data }) => {
  const isEpisode = pageContext.episodeSlug ? true : false

  const appState = useAppState()
  const appDispatch = useAppDispatch()

  const [relatedStories, setRelatedStories] = useState<IStory[]>([])
  const [isFavButtonDisabled, setIsFavButtonDisabled] = useState<boolean>(false)

  const allStories: IStory[] = data.allPrismicSeries.nodes

  const rawStory = data.series
  const story: IStoryWithEpisodesWithDescriptions = {
    ...rawStory,
    data: {
      ...rawStory.data,
      episodes: rawStory.data.episodes
        .map((e: any) => e.episode?.document?.data)
        .filter(notEmpty),
    },
  }
  const isSeriesLiked = appState.user?.data.favouriteSeries?.some(
    (favSerieSlug) => favSerieSlug === pageContext.slug
  )

  const storyLength = story.data.episodes.reduce(
    (acc, episode) => acc + (episode.total_length ?? 0),
    0
  )

  const likeButtonColor = isFavButtonDisabled
    ? colors.heartDisabled
    : colors.white

  const likeButtonIcon = isSeriesLiked ? 'Heart' : 'HeartEmpty'

  const episodesData = story.data.episodes

  const read = (index: number) => {
    appDispatch({
      type: 'updatePlayer',
      payload: {
        story: story,
        selectedEpisode: index,
        isReading: true,
        isPlaying: false,
      },
    })
  }

  const { authors, title, level_of_intensity, description, cover } = story.data
  const tags = story.tags

  useEffect(() => {
    const allRelatedStories: IStory[] = []
    allStories.forEach((story) => {
      let sameTagsCounter = 0
      for (const tag of tags) {
        if (story.tags.includes(tag) && story.data.title.text !== title.text) {
          sameTagsCounter++
          if (sameTagsCounter >= 2) {
            allRelatedStories.push(story)
            break
          }
        }
      }
    })

    setRelatedStories(allRelatedStories)
  }, [allStories])

  const isUserPaid = appState.userAuthToken

  const desktopBackground =
    data.prismicWebappPage.data.story_page_background_desktop
  const mobileBackground =
    data.prismicWebappPage.data.story_page_background_mobile

  const selectedEpisode = pageContext.episodeSlugs.findIndex(
    (slug) => slug === pageContext.episodeSlug
  )

  const onLike = async () => {
    if (appState.user && appState.userAuthToken) {
      setIsFavButtonDisabled(true)

      const response = await likeDislikeService(
        appState.userAuthToken,
        isSeriesLiked ? 'dislike' : 'like',
        'serie',
        pageContext.slug,
        appState.user
      )
      if (response) {
        appDispatch({
          type: 'updateUser',
          payload: response,
        })
      }
      setIsFavButtonDisabled(false)
    }
  }

  const onMore = async (event: any) => {
    appDispatch({
      type: 'updateOptionsPopup',
      payload: {
        coordinates: {
          x: event ? parseInt(event.pageX) : 0,
          y: event ? parseInt(event.pageY) : 0,
        },
      },
    })
  }

  return (
    <>
      <Seo
        title={`${title.text} - Series`}
        imageUrl={story.data.cover.url}
        description={
          story.data.metaDescription ??
          story.data.description.html ??
          'Rouze story'
        }
      />
      <Navigation
        backgroundColor={colors.primary1}
        navMode={'backButton'}
        likeButtonClick={() => onLike()}
        likeButtonColor={likeButtonColor}
        likeButtonIcon={likeButtonIcon}
        moreButton={() => onMore(undefined)}
      />
      <Page>
        <DesktopBgImage
          image={getImage(desktopBackground)}
          position="center top"
          height="auto"
        />
        <MobileBgImage
          image={getImage(mobileBackground)}
          position="center 80vw"
          height="100%"
          fit="contain"
        />
        <AppContainerStyled>
          {story && (
            <Wrapper>
              <WrapperRow>
                <GridCol xs={4} m={6}>
                  <Image className="storyImage">
                    <StyledBgImage image={getImage(cover)} />
                    <RatingPlayerButton>
                      <FlamesWrapper>
                        <RatingStyled>
                          {generateRating(level_of_intensity || 0)}
                        </RatingStyled>
                      </FlamesWrapper>
                      <GridColPlayerButton>
                        {(story.data.story_type == StoryType.LISTEN ||
                          story.data.story_type ==
                            StoryType.LISTEN_AND_READ) && (
                          <PlayerButton
                            size={'medium'}
                            variant={'Auto'}
                            serie={story}
                            episodeNumber={'all'}
                          ></PlayerButton>
                        )}
                        {(story.data.story_type == StoryType.READ ||
                          story.data.story_type ==
                            StoryType.LISTEN_AND_READ) && (
                          <PlayerButton
                            onClick={() =>
                              read(appState.player?.selectedEpisode || 0)
                            }
                            size={'medium'}
                            variant={PlayerButtonVariant.Read}
                          ></PlayerButton>
                        )}
                      </GridColPlayerButton>
                    </RatingPlayerButton>
                  </Image>
                </GridCol>
                <GridCol xs={4} m={6}>
                  <MobileContainer>
                    <HeartMoreStyled>
                      {isUserPaid && (
                        <LikeIcon
                          type={likeButtonIcon}
                          width={20}
                          height={19}
                          color={likeButtonColor}
                          onClick={() => onLike()}
                        ></LikeIcon>
                      )}
                      <More onClick={onMore}>
                        <MoreIcon type="More" width={24} height={6}></MoreIcon>
                      </More>
                    </HeartMoreStyled>
                    {isEpisode && (
                      <SerieTitle
                        onClick={() => navigate(`/series/${pageContext.slug}`)}
                      >
                        serie: {title.text}
                      </SerieTitle>
                    )}
                    <Title isEpisode={isEpisode}>
                      {isEpisode
                        ? episodesData[selectedEpisode].title.text
                        : title.text}
                    </Title>
                    <Length>
                      {isEpisode
                        ? `${
                            selectedEpisode + 1 + ' /' + episodesData.length
                          } aflevering - ${getStoryLength(storyLength)}`
                        : `${episodesData.length} aflevering - ${getStoryLength(
                            storyLength
                          )}`}
                    </Length>
                    <AllAuthors>
                      {authors.map(({ author }) =>
                        author.document?.data.name.text ? (
                          <Author
                            to={`/authors/${author.document?.slug}`}
                            key={`story-author-${author.document?.slug}`}
                          >
                            {author.document?.data.name.text}
                          </Author>
                        ) : (
                          <></>
                        )
                      )}
                    </AllAuthors>
                    <ReadMoreStyled
                      dangerousInnerHTMLCaption={
                        isEpisode
                          ? episodesData[selectedEpisode].description.html
                          : description.html
                      }
                    />
                    <Tags>{generateTags({ tags })}</Tags>
                  </MobileContainer>
                </GridCol>
                <GridCol xs={4} m={12}>
                  <SerieCard
                    episodes={episodesData}
                    flames={level_of_intensity || 0}
                    openEpisode={(index) =>
                      navigate(
                        `/series/${pageContext.slug}/${pageContext.episodeSlugs[index]}`
                      )
                    }
                    selectedEpisode={selectedEpisode}
                    story={story}
                  ></SerieCard>
                </GridCol>
                {relatedStories.length !== 0 && (
                  <GridCol xs={4} m={12}>
                    <StoriesList
                      stories={relatedStories}
                      title="Ook iets voor jou"
                    />
                  </GridCol>
                )}
              </WrapperRow>
            </Wrapper>
          )}
        </AppContainerStyled>
      </Page>
    </>
  )
}

const DesktopBgImage = styled(BgImage)`
  display: none;
  > div {
    max-width: 100% !important;
  }
  ${mqFrom.M`
    display: block;
  `}
`

const MobileBgImage = styled(BgImage)`
  display: block;
  ${mqFrom.M`
    display: none !important;
  `}
`

const StyledBgImage = styled(BgImage)`
  z-index: -1;
  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(1, 34, 33, 0) 0%,
      rgba(1, 34, 33, 0.85) 100%
    );
    mix-blend-mode: multiply;
    width: 100%;
    height: 80%;
    z-index: 1;
  }
`

const Page = styled(AppPage)`
  background-color: ${colors.primary1};
`
const AppContainerStyled = styled(AppContainer)`
  color: ${colors.neutralWhite};
  ${mqFrom.L`
    margin-top: 80px;
  `}
`

export const query = graphql`
  query loadPrismicSeries($slug: String!) {
    prismicWebappPage {
      data {
        story_page_background_desktop {
          gatsbyImageData
        }
        story_page_background_mobile {
          gatsbyImageData
        }
      }
    }
    allPrismicSeries {
      nodes {
        ...FullSeries
      }
    }
    series: prismicSeries(uid: { eq: $slug }) {
      ...FullSeriesWithEpisodeWithDescriptions
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`

export function generateRating(rating: number) {
  const content: JSX.Element[] = []
  for (let i = 0; i < 5; i++) {
    content.push(
      <Icon
        key={i}
        type="Flame"
        width={9}
        height={12}
        color={rating > i ? colors.red : colors.white}
      ></Icon>
    )
  }
  return content
}

function generateTags(props: any) {
  const tags = props['tags']

  return tags.map((tag: string) => (
    <Tag key={tag}>
      <TagButton
        caption={tag}
        mode="link"
        role="link"
        internalLink={`/search/${parseTag(tag)}`}
      ></TagButton>
    </Tag>
  ))
}

const RatingPlayerButton = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${mqFrom.M`
    position: absolute;
    bottom: 0;
  `}
`

const GridColPlayerButton = styled.div`
  padding: 0px;
  overflow: hidden;
  display: flex;

  > *:first-child {
    margin-right: 16px;
  }
  > *:nth-child(2) {
    margin-right: 12px;
  }
`

const Image = styled.div`
  position: relative;
  width: 100vw;
  height: 80vw;
  min-width: 250px;
  left: 0px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));

  ${mqFrom.M`
    width: 100%;
    display: block;
    height: 0px;
    padding-bottom: 100%;
    margin-bottom: 7px;
  `}
`

const Title = styled.div<{ isEpisode: boolean }>`
  ${applyTextStyle('headline1', 'desktop')}
  ${applyTextStyle('heading1', 'mobile')}
  overflow: hidden;
  width: 100%;
  margin-top: ${({ isEpisode }) => (isEpisode ? '0px' : '31px')};
`

const Length = styled.div`
  ${applyTextStyle('heading6')}
  margin: 16px 0;
`
const Author = styled(Link)`
  ${applyTextStyle('link')}

  &:after {
    content: '|';
    position: relative;
    padding-left: 6px;
    color: ${colors.white};
  }

  &:last-child:after {
    display: none;
  }
`

const ReadMoreStyled = styled(ReadMore)`
  ${applyTextStyle('body')}
  margin: 16px 0 26px 0;
  ${mqFrom.M`
    margin: 16px 0 32px 0;
  `}
`

const HeartMoreStyled = styled.div`
  display: none;
  ${mqFrom.M`
    display: flex;
    align-items: stretch;
    margin: 0;
    position: relative;
  `}
`

const FlamesWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`
const RatingStyled = styled.div`
  display: inline-box;
  span {
    margin-right: 2.4px;
  }
  ${mqFrom.L`
    span {
      margin-right: 3.59px;
    }
  `}
`

const Tags = styled.div`
  margin-top: 26px;
  ${mqFrom.L`
    margin-top: 32px;
  `}
`

const Tag = styled.div`
  display: inline-block;
  padding-right: 8px;
  padding-bottom: 8px;
`

const TagButton = styled(Button)`
  height: 28px;
  label {
    ${applyTextStyle('label')}
  }
`

const WrapperRow = styled(GridRow)`
  z-index: 2;
  position: relative;
`
const SerieTitle = styled.div`
  ${applyTextStyle('label')}
  cursor: pointer;
  margin-top: 31px;
  margin-bottom: 8px;
  ${mqFrom.M`
    margin-bottom: 16px;
  `}
`

const LikeIcon = styled(Icon)`
  cursor: pointer;
`
const MoreIcon = styled(Icon)`
  margin-left: 6px;
`

const More = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const AllAuthors = styled.div``

export default SingleStoryPage
