export const stripeDateToString = (stripeDate: number): string => {
  const date = new Date(stripeDate * 1000)
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

export const getBlogDate = (date: Date, minutesOfReading: number) => {
  return `${date.getDay()} ${date
    .toLocaleString('default', { month: 'short' })
    .toUpperCase()} ${date.getFullYear()} | LEESTIJD: ${minutesOfReading} MIN`
}

export const getStoryLength = (seconds: number): string => {
  if (seconds <= 1) {
    return ''
  }
  const minutes = Math.floor(seconds / 60)
  if (minutes < 60) {
    return minutes + ' min'
  } else {
    return `${Math.floor(minutes / 60)} u ${minutes % 60} min`
  }
}

export const getEpisodePreviewLength = (rawSeconds: number): string => {
  const seconds = Math.floor(rawSeconds)
  if (seconds <= 1) {
    return '15 sec'
  }
  const minutes = Math.floor(seconds / 60)
  if (minutes) {
    return `${minutes} min ${seconds % 60} sec`
  }
  return `${seconds} sec`
}
