import { IAuthUser } from '../../common/models/user'
import { LIKE_DISLIKE } from '../../constants/api.constants'
import errorHandler from './errorHandler'

export default (
  token: string,
  action: 'like' | 'dislike',
  type: 'author' | 'serie',
  slug: string,
  appUser: IAuthUser
) =>
  fetch(LIKE_DISLIKE(), {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      action,
      type,
      slug,
    }),
  })
    .then(errorHandler)
    .then(async (feedback: Response): Promise<IAuthUser | undefined> => {
      const jsonResponse = await feedback.json()
      if (jsonResponse.slugs && jsonResponse.slugs instanceof Array) {
        const newData = {
          ...appUser.data,
        }

        newData[type === 'author' ? 'favouriteAuthors' : 'favouriteSeries'] =
          jsonResponse.slugs

        return {
          ...appUser,
          data: newData,
        }
      }
    })
