import { createCookie, getCookie } from '../../utils/cookie.helper'

export const handleSocialMediaTracking = () => {
  // ?utm_source=test&utm_medium=testtest&utm_campaign=testtesttest&utm_content=testtesttestt
  const searchParams = new URLSearchParams(window.location.search)
  const utmSource = searchParams.get('utm_source')
  const utmMedium = searchParams.get('utm_medium')
  const utmCampaign = searchParams.get('utm_campaign')
  const utmContent = searchParams.get('utm_content')
  if (utmSource) {
    createCookie('utmSource', utmSource)
  }
  if (utmMedium) {
    createCookie('utmMedium', utmMedium)
  }
  if (utmCampaign) {
    createCookie('utmCampaign', utmCampaign)
  }
  if (utmContent) {
    createCookie('utmContent', utmContent)
  }
}

export const getSocialMediaTags = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const utmSource = searchParams.get('utm_source')
  const utmMedium = searchParams.get('utm_medium')
  const utmCampaign = searchParams.get('utm_campaign')
  const utmContent = searchParams.get('utm_content')
  return {
    Source: utmSource || getCookie('utmSource') || '',
    Medium: utmMedium || getCookie('utmMedium') || '',
    Campaign: utmCampaign || getCookie('utmCampaign') || '',
    Ad_content: utmContent || getCookie('utmContent') || '',
  }
}
