import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

interface IProps {
  className?: string
  height?: string
  fit?: string
  position?: string
  borderRadius?: string
  image?: IGatsbyImageData
}

const BgImage: React.FC<IProps> = (props) => {
  const { image } = props
  return image ? (
    <SmartImage
      {...props}
      image={image}
      alt={'test'}
      className={`fluid-bg-image ${props.className ?? ''}`}
      loading="lazy"
    ></SmartImage>
  ) : (
    <div></div>
  )
}

const SmartImage = styled(GatsbyImage).attrs<IProps>(({ className }) => ({
  className: className,
}))<IProps>`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => props.height || '100%'} !important;
  border-radius: ${(props) => props.borderRadius || '0'};

  // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
  img {
    border-radius: ${(props) => props.borderRadius || '0'};
    object-fit: ${(props) => props.fit || 'cover'} !important;
    object-position: ${(props) => props.position || '50% 50%'} !important;
    font-family: 'object-fit: ${(props) =>
      props.fit || 'cover'} !important; object-position: ${(props) =>
      props.position || '50% 50%'} !important;';
  }

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
  user-drag: none;

  img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
`

export default BgImage
