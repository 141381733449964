import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconPlay = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 18 25"
    fill="none"
  >
    <path
      d="M2.53856 24.3718L17.3574 13.5766C18.2142 12.9597 18.2142 11.726 17.3574 11.1091L2.53856 0.313917C1.49141 -0.457169 0 0.283073 0 1.54765V23.1381C0 24.4026 1.49141 25.1429 2.53856 24.3718Z"
      fill={props.color}
    />
  </svg>
)

export default IconPlay
