import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

// Styles
import {
  applyTextStyle,
  applyTextEllipsis,
} from '@monorepo/shared/styles/typography'

// Components
import { FieldMetaProps } from 'formik'
import colors from '@monorepo/shared/styles/colors'
import { Icon } from '@monorepo/shared/components/icon/'

// Types
export enum InputVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum InputType {
  Text = 'text',
  Email = 'email',
  Password = 'password',
  CheckBox = 'checkbox',
}

export type InputVariantType = 'primary' | 'secondary'
export type InputFieldType = 'text' | 'password' | 'checkbox' | 'email'
export interface IFormControlProps {
  label?: string // The label above the field
  additionalLabel?: string // Additional label, just underneath the main one
  disabled?: boolean
  defaultValue?: string | number
  errorMessage?: string
  meta?: FieldMetaProps<any>
  onChange?: (e?: any) => void
  handleChange?: (e?: any) => void
  value?: string | number
  checked?: boolean
  type?: InputFieldType
  variant?: InputVariantType
  readOnly?: boolean
  clear?: () => void
  id?: string
  autoCapitalize?: string
}

export interface IProps extends IFormControlProps {
  placeholder?: string
  name?: string
  withSearch?: boolean
  className?: string
  maxLength?: number
  button?: {
    label: string
    handleClick: () => void
    disabled?: boolean
  }
}

const Input = (props: IProps) => {
  const id = props.id
  const type = props.type || InputType.Text
  const errorMessage =
    props.meta && props.meta.error && props.meta.touched
      ? props.meta.error
      : props.errorMessage

  const [inputValue, setInputValue] = useState<string>('')
  const input = useRef<HTMLInputElement>(null)

  const [passwordShown, setPasswordShown] = useState<boolean>(false)

  const focus = () => {
    if (input.current) {
      input.current.focus()
    }
  }

  const clearValue = () => {
    if (input.current) {
      input.current.value = ''
    }
    if (props.clear) {
      props.clear()
    }
  }

  useEffect(() => {
    if (input.current) {
      setInputValue(input.current.value)
    }
  }, [input.current?.value])

  const togglePassword = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  return (
    <InputWrapper
      className={props.className}
      disabled={props.disabled}
      errorMessage={errorMessage}
    >
      {props.label && <InputLabel {...props}>{props.label}</InputLabel>}
      {props.label && props.additionalLabel && (
        <InputAdditionalLabel>{props.additionalLabel}</InputAdditionalLabel>
      )}
      <InputInnerWrapper>
        {props.withSearch && (
          <StyledIcon
            onClick={inputValue === '' ? focus : clearValue}
            type={inputValue === '' ? 'Search' : 'Close'}
            size={15}
            color={colors.neutralWhite}
          />
        )}
        {props.type === InputType.Password &&
          props.variant === InputVariant.Secondary && (
            <StyledIcon
              onClick={togglePassword}
              type="Eye"
              size={18}
              color={colors.neutralWhite}
            />
          )}

        <StyledInput
          id={id}
          ref={input}
          name={props.name}
          withSearch={props.withSearch}
          onChange={props.onChange}
          {...props}
          autoCapitalize={props.autoCapitalize}
          type={
            type !== InputType.Password
              ? type
              : passwordShown
              ? 'text'
              : 'password'
          }
          withButton={
            props.button !== undefined || props.maxLength !== undefined
          }
        />
        {props.maxLength && (
          <StyledText>
            {(props.meta?.value && props.meta?.value.length) || 0} /{' '}
            {props.maxLength}
          </StyledText>
        )}
      </InputInnerWrapper>
      <InputError>{errorMessage}</InputError>
    </InputWrapper>
  )
}

const InputLabel = styled.label<Pick<IFormControlProps, 'variant'>>`
  display: block;
  padding-left: 1px;
  padding-top: 16px;
  margin: 0 0 8px 16px;
  color: ${colors.neutralWhite};
  ${applyTextStyle('smallText', 'mobile')}
  ${(props) =>
    props.variant === InputVariant.Primary &&
    css`
      margin: 0;
    `}
  ${(props) =>
    props.variant === InputVariant.Secondary &&
    css`
      margin-left: 16px;
      margin-bottom: 8px;
    `}
`

const InputAdditionalLabel = styled.p`
  ${applyTextStyle('body')}
  margin: 0 0 8px;
  color: ${colors.white};
`

const InputInnerWrapper = styled.div`
  position: relative;
`

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 17px;
  right: 14.7px;
  cursor: pointer;
`

const StyledText = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`

const StyledInput = styled.input<
  Pick<IFormControlProps, 'variant'> & {
    withSearch?: boolean
    withButton?: boolean
  }
>`
  display: block;
  width: 100%;
  padding: ${({ withSearch, withButton }) =>
    withSearch
      ? '12px 48px 12px 16px'
      : withButton
      ? '12px 56px 12px 16px'
      : ''};
  outline: 0;
  ${applyTextStyle('body', 'mobile')}
  color: ${colors.white};
  transition: border-color 0.3s ease;
  background: transparent;
  ${({ withSearch }) =>
    withSearch &&
    `
    background: transparent;
  `}

  &:disabled {
    border-color: ${colors.white};
    pointer-events: none;
  }

  &:hover {
    border-color: ${colors.white};
  }

  ::placeholder {
    ${applyTextStyle('body', 'mobile')}
    color: rgba(255, 255, 255, 0.4);
    opacity: 1;
  }

  &:focus {
    border-color: ${colors.white};
    & + * path {
      fill: ${colors.white};
    }
  }

  &[type='checkbox'] {
    -webkit-appearance: none;
    background-color: transparent;
    border: 3px solid ${colors.white};
    box-shadow: 0;
    width: 18px;
    height: 18px;
    border-radius: 8px;
    display: inline-block;
    position: relative;
    padding: 0 !important;
  }

  &[type='checkbox']:active,
  &[type='checkbox']:checked:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  &[type='checkbox']:checked {
    background-color: ${colors.primary3};
    border-color: ${colors.primary3};
    box-shadow: 0;
    color: #99a1a7;
  }

  &[type='checkbox']:checked:after {
    content: '✔';
    font-size: 13px;
    position: absolute;
    top: -3px;
    left: 1px;
    color: ${colors.white};
  }

  ${(props) =>
    props.variant === InputVariant.Primary &&
    css`
      border-bottom: 2px solid ${colors.white};
      border-left: 0;
      border-right: 0;
      border-top: 0;
      padding: 8px 0;
    `}
  ${(props) =>
    props.variant === InputVariant.Secondary &&
    css`
      border: 2px solid ${colors.white};
      border-radius: 40px;
      padding: 12px 16px;
      :focus {
        background-color: rgba(255, 255, 255, 0.2);
      }
    `}
`

const InputWrapper = styled.div<
  Pick<IFormControlProps, 'disabled' | 'errorMessage'>
>`
  position: relative;
  display: block;
  margin-bottom: 5px;

  ${({ errorMessage }) =>
    errorMessage &&
    css`
      ${StyledInput} {
        border-color: ${colors.red};
        border-width: 3px;
        & + * path {
          fill: ${colors.red};
        }
      }

      ${InputError} {
        display: block;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      ${InputLabel}, ${InputAdditionalLabel} {
        color: ${colors.white};
      }
    `}
`

const InputError = styled.p<Pick<IFormControlProps, 'variant'>>`
  width: 100%;
  display: none;
  margin: 4px 0 0 16px;
  padding-left: 1px;
  color: ${colors.fdbkError};
  ${applyTextStyle('smallText', 'mobile')}
  ${applyTextEllipsis()}
  ${(props) =>
    props.variant === InputVariant.Primary &&
    css`
      padding: 4px 0 0;
    `}
  ${(props) =>
    props.variant === InputVariant.Secondary &&
    css`
      padding: 4px 0 0 16px;
    `}
`

export default Input
