import React from 'react'
import styled from 'styled-components'
import PlayerButton, { PlayerButtonVariant } from '../PlayerButton'
import { applyTextStyle } from '@monorepo/shared/styles/typography'
import { mqFrom } from '@monorepo/shared/styles/responsive'
import { IEpisodeWithDescriptions } from '@monorepo/shared/common/models/episode'
import MobileContainer from '../MobileContainer'
import { generateRating } from '../../dynamic-pages/series'
import colors from '@monorepo/shared/styles/colors'
import {
  IStoryWithEpisodes,
  StoryType,
} from '@monorepo/shared/common/models/story'
import { useAppDispatch } from '../../context/AppContext'

interface IProps {
  story: IStoryWithEpisodes
  episodes: IEpisodeWithDescriptions[]
  openEpisode: (index: number) => void
  flames: number
  selectedEpisode?: number
}

const SerieCard: React.FC<IProps> = ({
  episodes,
  flames,
  openEpisode,
  selectedEpisode,
  story,
}) => {
  const appDispatch = useAppDispatch()

  const read = (index: number) => {
    appDispatch({
      type: 'updatePlayer',
      payload: {
        story: story,
        selectedEpisode: index,
        isReading: true,
        isPlaying: false,
      },
    })
  }

  return (
    <>
      <MobileContainer>
        <Title>Afleveringen</Title>
      </MobileContainer>
      {episodes.map((episode, id) => (
        <RowContent
          selected={selectedEpisode === id}
          key={`serie-card-episode-${id}`}
        >
          <PlayerButtonCol>
            {(story.data.story_type == StoryType.LISTEN ||
              story.data.story_type == StoryType.LISTEN_AND_READ) && (
              <PlayerButton
                variant={'Auto'}
                size={'medium'}
                serie={story}
                episodeNumber={id}
              ></PlayerButton>
            )}
            {(story.data.story_type == StoryType.READ ||
              story.data.story_type == StoryType.LISTEN_AND_READ) && (
              <PlayerButton
                onClick={() => read(id)}
                size={'medium'}
                variant={PlayerButtonVariant.Read}
              ></PlayerButton>
            )}
          </PlayerButtonCol>
          <RightDetails>
            <EpisodeTextPart onClick={() => openEpisode(id)}>
              <EpisodeTitle>
                {id + 1}. {episode.title.text}
              </EpisodeTitle>
              <EpisodeDescription
                dangerouslySetInnerHTML={{
                  __html: episode.description.text.slice(0, 250),
                }}
              ></EpisodeDescription>
            </EpisodeTextPart>
            <EpisodeDetails>
              {episode.total_length && (
                <Length>{Math.floor(episode.total_length / 60)} min</Length>
              )}
              <EpisodeFlames>{generateRating(flames)}</EpisodeFlames>
            </EpisodeDetails>
          </RightDetails>
        </RowContent>
      ))}
    </>
  )
}

const PlayerButtonCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 0;
  padding-right: 16px;
  ${mqFrom.M`
    padding-right: 24px;
  `}

  & > *:nth-child(2) {
    margin-left: 16px;
  }
`

const RowContent = styled.div<{ selected: boolean }>`
  background: ${({ selected }) =>
    selected ? colors.whiteTransparent : undefined};
  border-bottom: 0.5px solid #ffffff;
  padding: 16px;
  display: flex;
  ${mqFrom.M`
    padding: 24px;
  `}
`

const EpisodeTextPart = styled.div`
  cursor: pointer;
  ${mqFrom.M`
    margin-right: 32px;
    flex-basis: 65%;
    max-width: 65%;
  `}
`

const EpisodeDetails = styled.div`
  padding-top: 4px;
  ${mqFrom.M`
    padding: 0;
    align-items: center;
  `}
  display: flex;
`

const EpisodeFlames = styled.div``

const Length = styled.div`
  ${applyTextStyle('heading6')}
  padding-right: 10px;
`

const EpisodeTitle = styled.div`
  ${applyTextStyle('heading4')}
  margin: 0;
  padding: 0;
`

const EpisodeDescription = styled.div`
  ${applyTextStyle('smallText')}
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 3px;
`

const Title = styled.div`
  ${applyTextStyle('heading3')}
  padding-top: 38px;
  padding-bottom: 20px;
`

const RightDetails = styled.div`
  flex-grow: 1;
  ${mqFrom.M`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`

export default SerieCard
