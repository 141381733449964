// Config
const API_URL = process.env.GATSBY_API_URL || '/.netlify/functions/api'

const BASE_API_URL = `${API_URL}`

// Content
export const GET_USER = () => `${BASE_API_URL}/user/self`

export const LIKE_DISLIKE = () => `${BASE_API_URL}/user/favourites/authors`

export const HISTORY = () => `${BASE_API_URL}/history`
