import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconNextEpisode = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 12 14"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7.94735L1.53 13.2411C0.863951 13.6574 0 13.1785 0 12.3931V1.0016C0 0.216164 0.863952 -0.262679 1.53 0.153601L10 5.44735V1.19735C10 0.645067 10.4477 0.197352 11 0.197352C11.5523 0.197352 12 0.645067 12 1.19735V12.1974C12 12.7496 11.5523 13.1974 11 13.1974C10.4477 13.1974 10 12.7496 10 12.1974V7.94735Z"
      fill={props.color}
    />
  </svg>
)

export default IconNextEpisode
