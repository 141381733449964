import React from 'react'
import colors from '@monorepo/shared/styles/colors'
import { IIconPropsMore as IProps } from './IIconProps'

const IconMore = (props: IProps) => (
  <svg
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 24 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 6C12.7956 6 13.5587 5.68393 14.1213 5.12132C14.6839 4.55871 15 3.79565 15 3C15 2.20435 14.6839 1.44129 14.1213 0.87868C13.5587 0.316071 12.7956 0 12 0C11.2044 0 10.4413 0.316071 9.87868 0.87868C9.31607 1.44129 9 2.20435 9 3C9 3.79565 9.31607 4.55871 9.87868 5.12132C10.4413 5.68393 11.2044 6 12 6ZM3 6C3.79565 6 4.55871 5.68393 5.12132 5.12132C5.68393 4.55871 6 3.79565 6 3C6 2.20435 5.68393 1.44129 5.12132 0.87868C4.55871 0.316071 3.79565 0 3 0C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3C0 3.79565 0.31607 4.55871 0.87868 5.12132C1.44129 5.68393 2.20435 6 3 6ZM21 6C21.7956 6 22.5587 5.68393 23.1213 5.12132C23.6839 4.55871 24 3.79565 24 3C24 2.20435 23.6839 1.44129 23.1213 0.87868C22.5587 0.316071 21.7956 0 21 0C20.2044 0 19.4413 0.316071 18.8787 0.87868C18.3161 1.44129 18 2.20435 18 3C18 3.79565 18.3161 4.55871 18.8787 5.12132C19.4413 5.68393 20.2044 6 21 6Z"
      fill={props.color}
    />
  </svg>
)

IconMore.defaultProps = {
  color: colors.white,
}

export default IconMore
