export default {
  general: {
    authors: 'Schrijvers',
    oneMoment: 'Een moment aub...',
  },
  homePage: {
    seoTitle: 'Home Rouze - erotische verhalen',
    seoDescription: 'Rouze app',
    gratisWeekTitle: 'Gratis proefverhalen',
    newTitle: 'Nieuw',
    advertisementTitle: 'Neem je plezier serieus',
    advertisementText: 'Word lid van Rouze en wakker het vuur in jezelf aan.',
    advertisementButton: 'Probeer gratis',
    extraSpicy: 'Extra spicy',
    musicStories: 'Sexy songs',
  },
  '404Page': {
    seoTitle: 'Not found',
    seoDescription: 'Oeps, niet gevonden 404 page | Rouze app',
    text: 'Oeps, pagina niet gevonden...',
  },
  authorsPage: {
    seoTitle: 'Alle schrijvers Rouze',
    seoDescription: 'Alle schrijvers | Rouze App',
    title: 'Alle schrijvers',
    resultsText: 'schrijvers',
  },
  forgotPassword: {
    seoTitle: 'Wachtwoord vergeten',
    seoDescription: 'Wachtwoord vergeten | Rouze App',
    title: 'Wachtwoord vergeten?',
    emailOne: 'Voer het e-mailadres in waarmee je je hebt aangemeld.',
    emailOnePlaceholder: 'Voer hier je e-mailadres in',
    emailHelp:
      'Hulp nodig bij inloggen? Neem contact op met <a href="mailto:info@rouze.me">info@rouze.me</a>',
    button: 'Versturen',
    successMessage:
      'Als er een account is met e-mailadres ${email}, dan is er zojuist een reset-link gestuurd. Yeah!',
    errorMessage: 'Onbekende e-mail',
  },
  library: {
    seoTitle: 'Bibliotheek Rouze verhalen',
    seoDescription: 'Bibliotheek | Rouze app',
    loginRequired: 'Login nodig',
    startProofweek: 'Start je gratis proefperiode',
    startProofweekDescription:
      'Sla je favoriete verhalen en schrijvers op voor later, abonneer nu.',
    subscribeButton: 'Abonneer',
    libraryTitle: 'Vul je bibliotheek',
    libraryDescription: 'Met dit icoontje voeg je je favorieten toe',
    findAStory: 'Zoek een verhaal',
    findAnAuthor: 'Zoek een auteur',
    stories: 'Verhalen',
    authors: 'Schrijvers',
    title: 'Bibliotheek',
    storiesTab: 'VERHALEN',
    historyTab: 'Geschiedenis',
    authorsTab: 'Schrijvers',
  },
  login: {
    seoTitle: 'Inloggen Rouze sexy luisterverhalen',
    seoDescription: 'Inloggen | Rouze app',
    error: 'Invalid email or password',
    title: 'Inloggen',
    button: 'Inloggen',
    noAccount: 'Heb je nog geen account?',
    register: 'Ik wil me registreren',
    forgottenPassword: 'Wachtwoord vergeten?',
    forgottenPasswordButton: 'Ik ben mijn wachtwoord vergeten',
    accessWithFacebook: 'Ga door met Facebook',
    accessWithGoogle: 'Ga door met Google',
    password: 'Wachtwoord',
  },
  resetPassword: {
    seoTitle: 'Reset wachtwoord',
    seoDescription: 'Reset wachtwoord Rouze',
    errorMessage: 'Oepsie, er ging iets mis',
    passwordsDoNotMatch: 'Wachtwoorden komen niet overeen',
    title: 'Wachtwoord opnieuw instellen',
    newPassword: 'Nieuw wachtwoord',
    newPasswordRepeat: 'Nieuw wachtwoord herhalen',
    button: 'Versturen',
    password: 'Wachtwoord',
    passwordRepeat: 'Herhaal wachtwoord',
  },
  search: {
    seoTitle: 'Zoeken',
    seoDescription: 'Zoeken | Rouze app',
    title: 'Zoeken',
    searchPlaceholder: 'trio, romantisch',
    searchByTag: 'Ontdek met tag',
    moodsTitle: 'Mood',
    storiesResult: 'Verhalen',
    showAllStories: 'Toon alle verhalen',
    authorsResult: 'Topschrijvers',
    storiesTitle: 'Verhalen',
    allStories: 'Alle schrijvers',
    noResults: 'Geen resultaten, helaas',
    tryAnotherSearchText: 'Probeer een ander zoekwoord',
  },
  selectPlan: {
    seoTitle: 'Selecteer abonnement',
    seoDescription: 'Selecteer abonnement | Rouze app',
  },
  subscribe: {
    seoTitle: 'Abonneer',
    seoDescription: 'Abonneer | Rouze app',
  },
  subscription: {
    seoTitle: 'Subscribe',
    seoDescription: 'Subscribe | Rouze app',
  },
  accountDetails: {
    deleteAccountPopup:
      'Let op! Hiermee verwijder je al je gegevens en zet je je huidige abonnement stop. Weet je zeker dat je door wilt gaan?',
    changePasswordButton: 'Wijzig wachtwoord',
    currentPassword: 'Huidig wachtwoord',
    newPassword: 'Nieuw wachtwoord',
    confirmPasword: 'Bevestig wachtwoord',
    save: 'Wachtwoord opslaan',
    cancel: 'Annuleer',
    firstName: 'Jouw voornaam',
    yourEmail: 'Jouw e-mail',
    deleteAccount: 'Verwijder account',
    logOut: 'Log uit',
    accountDetailsTab: 'ACCOUNTDETAILS',
    subscriptionDetailsTab: 'ABONNEMENT BEHEREN',
  },
  bankForm: {
    seoTitle: 'Bank details',
    seoDescription: 'Bank details | Rouze App',
    yearSubscriptionText:
      'Voor de proefperiode vragen we ter controle €0,01. Als je daarna doorgaat wordt jaarlijks een bedrag van €36 geïncasseerd. Altijd opzegbaar.',
    monthSubscriptionText:
      'Er wordt maandelijks een bedrag van €4,99 van je rekening afgeschreven. Altijd opzegbaar.',
    yearSubscriptionWithToyText:
      'Voor de proefperiode vragen we ter controle €0,01. Als je daarna doorgaat wordt het eerste jaar €60 geïncasseerd, daarna € 36. Altijd opzegbaar.',
  },
  signup: {
    seoTitle: 'Inschrijven Rouze',
    seoDescription: 'Inschrijven Rouze',
    toSignupWithEmail: 'Aanmelden met e-mail',
    socialMediaTerms:
      'Als je doorgaat met aanmelden via social media, accepteer je onze',
    socialMediaTermsLinkText: 'algemene voorwaarden',
    email: 'E-mail',
    repeatEmail: 'Herhaal e-mail',
    privacyCheckbox:
      'Ik ga akkoord met de Algemene voorwaarden en Privacy verklaring',
    newsletterCheckbox:
      'Ik ontvang graag updates, aanbiedingen en promoties via de nieuwsbrief',
    makeAccountButton: 'Maak account',
    alreadyLoggedIn: 'Hey sexy, je bent al ingelogd',
  },
  subscriptionResult: {
    pending: 'Bezig...',
    pendingDescription: 'Momentje, de betaling wordt gecheckt...',
    errorMessage: 'Oepsie, er ging iets mis',
    errorMessageDescription: 'Je kunt opnieuw proberen',
  },
  authors: {
    noMoreStories: 'Deze schrijver heeft nog geen verhalen... coming soon!',
  },
  cookieBanner: {
    text: 'Door dit bericht te sluiten, volg je ons cookie beleid en sta je Rouze toe cookies te gebruiken op dit apparaat',
    link: 'cookie policy',
  },
  howDoYouFeel: {
    title: 'Wat is je mood?',
  },
  subscriptionBox: {
    cancelSubscription: 'Cancel abonnement',
    resumeSubscription: 'Cancel je opzegging',
    skipTrial: 'Skip trial',
    switchPlan: 'Wijzig abonnement',
    areYouSure: 'Weet je het zeker?',
    areYouSureYouWantToSwitch:
      'Weet je zeker dat je van abonnement wilt wisselen? Je wordt gefactureerd wanneer je huidige periode eindigt',
    areYouSureYouWantToSubscribe: 'Weet je zeker dat je je wilt abonneren?',
  },
  player: {
    previous: 'Vorige',
    next: 'Volgende',
  },
  popups: {
    finishSubscription:
      'Je hebt nog geen abonnement. Wil je er nu eentje kiezen? Je kunt op ieder gewenst moment opzeggen in je accountpagina',
  },
}
