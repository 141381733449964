import React from 'react'
import styled, { css } from 'styled-components'
import {
  IGridBreakpoint,
  IGridColProps,
  ColSpanAmount,
} from '@monorepo/shared/common/models/grid'
import { gridConfig } from '@monorepo/shared/styles/responsive'

interface IProps extends IGridColProps {
  // Default tag name is 'div'
  tagName?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  className?: string
  children?: React.ReactNode
}

const GridCol: React.FC<IProps> = ({
  tagName,
  xs,
  m,
  l,
  children,
  className,
}) => {
  !tagName && (tagName = 'div')
  return (
    <Col {...{ xs, m, l }} as={tagName} className={className}>
      {children}
    </Col>
  )
}

const getColStyles = (bp: IGridBreakpoint, colSpan: ColSpanAmount) => {
  const size = bp.startsFrom

  return css`
    @media only screen and (min-width: ${size}px) {
      flex-basis: ${(100 / bp.maxColumns) * colSpan}%;
      max-width: ${(100 / bp.maxColumns) * colSpan}%;
    }
  `
}

const findBreakpoint = (bpName: string) =>
  gridConfig.breakpoints.find((bp) => bp.name === bpName)

export const Col = styled.div<IGridColProps>`
  flex-grow: 1;
  flex-shrink: 0;
  ${(props) => props.xs && getColStyles(findBreakpoint('xs')!, props.xs)}
  ${(props) => props.m && getColStyles(findBreakpoint('m')!, props.m)}
  ${(props) => props.l && getColStyles(findBreakpoint('l')!, props.l)}
  ${(props) => props.xl && getColStyles(findBreakpoint('xl')!, props.xl)}
`

export default GridCol
