import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconFastBackward = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 20 22"
    fill="none"
  >
    <path
      d="M8.1243 14.5H8.7659V10.2725H8.11844L7.00516 11.0635V11.6846L8.10965 10.9229H8.1243V14.5ZM11.4011 14.5703C12.4089 14.5703 13.0154 13.7295 13.0154 12.3877V12.3818C13.0154 11.04 12.4089 10.2021 11.4011 10.2021C10.3904 10.2021 9.78684 11.04 9.78684 12.3818V12.3877C9.78684 13.7295 10.3904 14.5703 11.4011 14.5703ZM11.4011 14.0225C10.7976 14.0225 10.4489 13.4014 10.4489 12.3877V12.3818C10.4489 11.3682 10.7976 10.7529 11.4011 10.7529C12.0017 10.7529 12.3532 11.3682 12.3532 12.3818V12.3877C12.3532 13.4014 12.0017 14.0225 11.4011 14.0225Z"
      fill={props.color}
    />
    <path
      d="M0.5 12C0.5 11.7239 0.723858 11.5 1 11.5C1.27614 11.5 1.5 11.7239 1.5 12H0.5ZM6.175 3.85091L5.96225 3.39843L6.175 3.85091ZM3.25 6.0469L5.51535 0.736384L8.98171 5.35349L3.25 6.0469ZM1.5 12C1.5 16.6944 5.30558 20.5 10 20.5V21.5C4.75329 21.5 0.5 17.2467 0.5 12H1.5ZM10 20.5C14.6944 20.5 18.5 16.6944 18.5 12H19.5C19.5 17.2467 15.2467 21.5 10 21.5V20.5ZM18.5 12C18.5 7.30558 14.6944 3.5 10 3.5V2.5C15.2467 2.5 19.5 6.75329 19.5 12H18.5ZM10 3.5C8.70737 3.5 7.48343 3.7882 6.38775 4.30338L5.96225 3.39843C7.18827 2.82196 8.55723 2.5 10 2.5V3.5Z"
      fill={props.color}
    />
  </svg>
)

export default IconFastBackward
