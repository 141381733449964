import amplitude from 'amplitude-js'
// declare const amplitude: any

// const exitCallback = function () {
//   alert('enddd')
//   amplitude.getInstance().logEvent('User closed app')
// }

export const initAmplitude = (userId?: string) => {
  amplitude
    .getInstance()
    .init(process.env.GATSBY_AMPLITUDE_API_KEY || '', userId, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // onExitPage: exitCallback,
      trackingOptions: {
        ip_address: false,
      },
    })
}

export const setAmplitudeUserDevice = (installationToken: string) => {
  amplitude.getInstance().setDeviceId(installationToken)
}

export const setAmplitudeUserId = (userId: string) => {
  amplitude.getInstance().setUserId(userId)
}

export const setAmplitudeUserProperties = (properties: any) => {
  amplitude.getInstance().setUserProperties(properties)
}

export const addAmplitudeUserProperty = (key: string, amount = 1) => {
  const identify = new amplitude.Identify().add(key, amount)
  amplitude.getInstance().identify(identify)
}

export const sendAmplitudeData = (eventType: string, eventProperties: any) => {
  amplitude.getInstance().logEvent(eventType, eventProperties)
}
