import React from 'react'
import colors from '@monorepo/shared/styles/colors'
import { IIconPropsMore as IProps } from './IIconProps'
import BackgroundMobile from '../../images/logo.svg'
import styled from 'styled-components'

const IconLogo = (props: IProps) => (
  <Logo
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
  />
)

IconLogo.defaultProps = {
  color: colors.white,
}

const Logo = styled.div`
  background: url(${BackgroundMobile}) center center / contain no-repeat;
`
export default IconLogo
