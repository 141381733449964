import React from 'react'
import styled, { css } from 'styled-components'

// Styles
import { fastTransition } from '@monorepo/shared/styles/effects'

// Components
import colors from '@monorepo/shared/styles/colors'
import { Icon } from '@monorepo/shared/components/icon/'
import { mqFrom } from '@monorepo/shared/styles/responsive'
import { IStoryWithEpisodes } from '@monorepo/shared/common/models/story'
import { useAppState, useAppDispatch } from '../context/AppContext'

// Types

export enum PlayerButtonVariant {
  Play = 'Play',
  Pause = 'Pause',
  Read = 'Read',
}

export type PlayerButtonVariantType = 'Play' | 'Pause' | 'Read' | 'Auto'

interface IProps {
  disabled?: boolean
  variant?: PlayerButtonVariantType
  className?: string
  image?: string
  size?: 'small' | 'medium' | 'large'
  onClick?: (event?: React.MouseEvent) => void
  serie?: IStoryWithEpisodes
  episodeNumber?: number | 'all'
}

interface IButtonProps extends Pick<IProps, 'image'> {
  paddingMobile: number
  paddingDesktop: number
}

const PlayerButton = (props: IProps) => {
  // const [isHovered, setIsHovered] = useState<boolean>(false)

  const appState = useAppState()
  const appDispatch = useAppDispatch()

  const size = props.size || 'large'

  const getAutoPlayButton = () => {
    if (
      appState.player?.isPlaying &&
      appState.player?.story.slug === props.serie?.slug &&
      (appState.player?.selectedEpisode === props.episodeNumber ||
        props.episodeNumber === 'all')
    ) {
      return 'Pause'
    } else {
      return 'Play'
    }
  }

  const variant = props.variant === 'Auto' ? getAutoPlayButton() : props.variant

  const onClickAuto = (mode: 'Play' | 'Pause') => {
    if (mode === 'Pause') {
      const playerStory = appState.player?.story || props.serie
      if (playerStory) {
        appDispatch({
          type: 'updatePlayer',
          payload: {
            story: playerStory,
            selectedEpisode:
              appState.player?.selectedEpisode ??
              (props.episodeNumber == 'all' ? 0 : props.episodeNumber) ??
              0,
            isPlaying: false,
          },
        })
      }
    } else {
      // Clicking play may mean 2 things
      // Either the user has paused the CURRENT story and episode. In this case, just continue playing
      // The user might have clicked a DIFFERENT episode or STORY, in that case, update them both

      if (props.serie && props.episodeNumber !== undefined) {
        appDispatch({
          type: 'updatePlayer',
          payload: {
            isReading: false,
            story: props.serie,
            selectedEpisode:
              props.episodeNumber == 'all'
                ? appState.player?.story.slug === props.serie.slug &&
                  appState.player?.selectedEpisode <
                    props.serie.data.episodes.length
                  ? appState.player?.selectedEpisode
                  : 0
                : props.episodeNumber,
            isPlaying: true,
          },
        })
      }
    }
    // if (!appState.player?.isPlaying && props.story && props.episodeNumber) {
    //   appDispatch({
    //     type: 'updatePlayer',
    //     payload: {
    //       story: props.story,
    //       selectedEpisode: props.episodeNumber,
    //       isPlaying: true,
    //     },
    //   })
    // } else {
    //   appDispatch({
    //     type: 'updatePlayer',
    //     payload: {
    //       story: headerStory,
    //       selectedEpisode: 0,
    //       isPlaying: false,
    //     },
    //   })
    // }
  }

  const iconSizeSettings = {
    large: {
      iconSize: 24,
      paddingMobile: 24,
      paddingDesktop: 40,
    },
    medium: {
      iconSize: 20,
      paddingMobile: 24,
      paddingDesktop: 24,
    },
    small: {
      iconSize: 12,
      paddingMobile: 24,
      paddingDesktop: 12,
    },
  }[size]
  return (
    <StyledButton
      onClick={
        props.onClick ??
        (() => variant && onClickAuto(variant === 'Play' ? 'Play' : 'Pause'))
      }
      className={props.className}
      disabled={props.disabled}
      image={props.image ? props.image : ''}
      paddingDesktop={iconSizeSettings.paddingDesktop}
      paddingMobile={iconSizeSettings.paddingMobile}
    >
      <Icon
        size={iconSizeSettings.iconSize}
        color={colors.white}
        type={variant ? variant : PlayerButtonVariant.Play}
      />
      {/* {props.icon !== undefined && (
        <Icon size={iconSize} color={iconColor} type={props.icon} />
      )} */}
    </StyledButton>
  )
}

export const StyledButton = styled.button<IButtonProps>`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  justify-content: center;
  display: flex;
  outline: none;
  cursor: pointer;
  transition: color ${fastTransition}, background-color ${fastTransition};
  outline: none;
  border: 2px solid ${colors.white};
  border-radius: 100%;
  background-color: ${colors.transparent};

  ${mqFrom.M`
    &:hover {
      background-color: ${colors.whiteTransparent};
    }
  `}

  &:disabled {
    cursor: default;
    pointer-events: none;
    label {
      cursor: default;
    }
  }

  ${({ paddingMobile, paddingDesktop }) =>
    css`
      padding: ${paddingMobile}px;
      ${mqFrom.M`
        padding: ${paddingDesktop}px;
      `}
    `}
`

export default PlayerButton
