import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Link, navigate } from 'gatsby'
import BackgroundMobile from '@monorepo/shared/images/bg-navigation.svg'
import { Icon } from '@monorepo/shared/components/icon/'
// Styling
import colors from '@monorepo/shared/styles/colors'
import {
  fastDuration,
  fastTransition,
  slowDuration,
  slowTransition,
} from '@monorepo/shared/styles/animations'
import { applyTextStyle } from '@monorepo/shared/styles/typography'
import { mqFrom } from '@monorepo/shared/styles/responsive'
import { globalHistory } from '@reach/router'
import Button, { ButtonVariant } from './Button'
import {
  deleteAuthCookie,
  getAuthCookie,
} from '@monorepo/shared/utils/cookie.helper'
import { hexToRGB } from '@monorepo/shared/utils/colors.helper'
import { IconType } from './icon/IconType'

const API_URL = process.env.GATSBY_ROUZE_APP_URL

interface IProps {
  className?: string
  backgroundColor?: string
  forceBackgroundColor?: string
  navMode?: 'full' | 'backButton'
  likeButtonClick?: () => any
  likeButtonColor?: string
  likeButtonIcon?: IconType
  moreButton?: () => any
  siteMode?: boolean
}

type OpenedPages =
  | 'search'
  | 'authors'
  | 'library'
  | 'over-ons'
  | 'blog'
  | 'cadeau'
  | undefined

const Navigation: React.FC<IProps> = (props) => {
  const {
    className,
    backgroundColor,
    navMode,
    likeButtonClick,
    likeButtonColor,
    likeButtonIcon,
    moreButton,
    forceBackgroundColor,
    siteMode,
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [openingChanged, setOpeningChanged] = useState<boolean>(false)
  const [topRightUserShown, setTopRightUserShown] = useState<boolean>(false)

  const [openedPage, setOpenedPage] = useState<OpenedPages>()

  const usedNavMode = navMode ?? 'full'

  const isAuthenticated = getAuthCookie() ? true : false

  useEffect(() => {
    const path = globalHistory.location.pathname
    if (path.startsWith('/search')) {
      setOpenedPage('search')
    } else if (path.startsWith('/authors')) {
      setOpenedPage('authors')
    } else if (path.startsWith('/library')) {
      setOpenedPage('library')
    } else if (path.startsWith('/over-ons')) {
      setOpenedPage('over-ons')
    } else if (path.startsWith('/blog')) {
      setOpenedPage('blog')
    } else if (path.startsWith('/cadeau')) {
      setOpenedPage('cadeau')
    }
    setTopRightUserShown(true)
  }, [])

  const toggleMenu = () => {
    setOpeningChanged(true)
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    if (isOpen) {
      setOpeningChanged(true)
      setIsOpen(false)
    }
  }

  const logout = () => {
    deleteAuthCookie()
    const logoutEvent = new Event('logout')
    document.dispatchEvent(logoutEvent)
    closeMenu()
  }

  return (
    <NavBar
      className={className}
      backgroundColor={backgroundColor}
      forceBackgroundColor={forceBackgroundColor}
    >
      <TopNav>
        <NavSmall>
          {usedNavMode === 'full' && (
            <Hamburger
              onClick={toggleMenu}
              open={isOpen}
              openingChanged={openingChanged}
            >
              <HamburgerRow />
              <HamburgerRow />
              <HamburgerRow />
            </Hamburger>
          )}
          {usedNavMode === 'backButton' && (
            <BackButton
              onClick={() =>
                window.history.length > 1 ? navigate(-1) : navigate('/')
              }
            >
              <IconStyled type="ArrowLeft" size={20} color={colors.white} />
            </BackButton>
          )}
        </NavSmall>
        <NavLogo>
          {usedNavMode === 'full' && (
            <Logo>
              <Link to="/">
                <IconStyled type="Logo" width={80} height={30} />
              </Link>
            </Logo>
          )}
        </NavLogo>
        <NavSmall>
          {topRightUserShown && (
            <RightButtons>
              {likeButtonClick && (
                <NavRightButton>
                  <IconStyled
                    type={likeButtonIcon ?? 'Heart'}
                    width={20}
                    height={20}
                    color={likeButtonColor}
                    onClick={() => likeButtonClick()}
                  />
                </NavRightButton>
              )}
              {moreButton && (
                <NavRightButton onClick={moreButton}>
                  <IconStyled type="More" width={24} height={6}></IconStyled>
                </NavRightButton>
              )}
              {siteMode && (
                <NavRightButton>
                  <SmallButton
                    mode="link"
                    variant="secondary"
                    externalLink={`${API_URL}`}
                    caption="Open app"
                  />
                </NavRightButton>
              )}
              {!moreButton && !likeButtonClick && isAuthenticated && !siteMode && (
                <NavRightButton onClick={() => navigate('/profile/account')}>
                  <IconStyled
                    type="Person"
                    size={16}
                    color={colors.neutralWhite}
                  />
                </NavRightButton>
              )}
            </RightButtons>
          )}
        </NavSmall>
      </TopNav>
      <TopNavDesktop>
        <LogoDesktop to="/">
          <IconStyled type="Logo" width={150} height={54.73} />
        </LogoDesktop>
        {!siteMode && (
          <LeftNav>
            <DesktopNavLink
              to="/authors"
              onClick={closeMenu}
              selected={openedPage == 'authors'}
            >
              Schrijvers
            </DesktopNavLink>
            {isAuthenticated && (
              <DesktopNavLink
                to="/library"
                onClick={closeMenu}
                selected={openedPage == 'library'}
              >
                Bibliotheek
              </DesktopNavLink>
            )}

            <DesktopNavLink
              to="/search"
              onClick={closeMenu}
              selected={openedPage == 'search'}
            >
              Zoeken
            </DesktopNavLink>
          </LeftNav>
        )}
        {siteMode && (
          <LeftNav>
            <DesktopNavLink
              to="/over-ons"
              onClick={closeMenu}
              selected={openedPage == 'over-ons'}
            >
              Over ons
            </DesktopNavLink>
            <DesktopNavLink
              to="/"
              onClick={(e: any) => {
                e.preventDefault()
                window.location.href = `${process.env.GATSBY_ROUZE_APP_URL}/subscription`
              }}
              selected={false}
            >
              Abonneer
            </DesktopNavLink>

            <DesktopNavLink
              to="/blog"
              onClick={closeMenu}
              selected={openedPage == 'blog'}
            >
              Blog
            </DesktopNavLink>
            <DesktopNavLink
              to="/cadeau"
              onClick={closeMenu}
              selected={openedPage == 'cadeau'}
            >
              Cadeau
            </DesktopNavLink>
          </LeftNav>
        )}
        {!siteMode && (
          <RightNav>
            {isAuthenticated && (
              <DesktopNavItem onClick={() => navigate('/profile/account')}>
                <IconStyled
                  type="Person"
                  size={24}
                  color={colors.neutralWhite}
                />
              </DesktopNavItem>
            )}
            {!isAuthenticated && (
              <DesktopNavItem onClick={() => navigate('/login')}>
                Log in
              </DesktopNavItem>
            )}
            {!isAuthenticated && (
              <Button
                onClick={() => navigate('/subscription')}
                caption="Maak account"
                variant={ButtonVariant.Secondary}
              />
            )}
          </RightNav>
        )}
        {siteMode && (
          <RightNav>
            <Button
              externalLink={`${process.env.GATSBY_ROUZE_APP_URL}`}
              mode="link"
              caption="Open app"
              variant={ButtonVariant.Secondary}
            />
            {isAuthenticated && (
              <DesktopNavItem
                onClick={() =>
                  (window.location.href = `${API_URL}/profile/account`)
                }
              >
                <IconStyled
                  type="Person"
                  size={24}
                  color={colors.neutralWhite}
                />
              </DesktopNavItem>
            )}
            {!isAuthenticated && (
              <DesktopNavItem
                onClick={() => (window.location.href = `${API_URL}/login`)}
              >
                Log in
              </DesktopNavItem>
            )}
          </RightNav>
        )}
      </TopNavDesktop>
      {!siteMode && (
        <Menu open={isOpen}>
          {isAuthenticated && (
            <StyledNavButtonButton
              onClick={logout}
              caption="Log uit"
              variant={ButtonVariant.Secondary}
            />
          )}
          {!isAuthenticated && (
            <StyledNavButtonButton
              onClick={() => navigate('/subscription')}
              caption="Maak account"
              variant={ButtonVariant.Secondary}
            />
          )}
          <NavLink to="/" onClick={closeMenu} open={isOpen}>
            Home
          </NavLink>
          <NavLink to="/search" onClick={closeMenu} open={isOpen}>
            Zoeken
          </NavLink>
          <NavLink to="/authors" onClick={closeMenu} open={isOpen}>
            Schrijvers
          </NavLink>
          {isAuthenticated && (
            <NavLink to="/library" onClick={closeMenu} open={isOpen}>
              Bibliotheek
            </NavLink>
          )}
          {!isAuthenticated && (
            <NavLink to="/login" onClick={closeMenu} open={isOpen}>
              Log in
            </NavLink>
          )}
        </Menu>
      )}
      {siteMode && (
        <Menu open={isOpen}>
          <StyledNavButtonButton
            onClick={() =>
              (window.location.href = `${process.env.GATSBY_ROUZE_APP_URL}/subscription`)
            }
            caption="Open app"
            variant={ButtonVariant.Secondary}
          />
          {!isAuthenticated && (
            <NavLink
              to="/"
              onClick={(e: any) => {
                e.preventDefault()
                window.location.href = `${process.env.GATSBY_ROUZE_APP_URL}/login`
              }}
              open={isOpen}
            >
              Log in
            </NavLink>
          )}
          <NavLink to="/over-ons" open={isOpen}>
            Over ons
          </NavLink>
          <NavLink to="/" onClick={closeMenu} open={isOpen}>
            Abonneer
          </NavLink>
          <NavLink to="/blog" onClick={closeMenu} open={isOpen}>
            Blog
          </NavLink>
          <NavLink to="/cadeau" onClick={closeMenu} open={isOpen}>
            Cadeau
          </NavLink>
        </Menu>
      )}
    </NavBar>
  )
}

// Important - rest of style is in global.css to improve performance
const NavBar = styled.nav<{
  backgroundColor?: string
  forceBackgroundColor?: string
}>`
  background-color: ${({ forceBackgroundColor }) =>
    `linear-gradient(0deg, ${hexToRGB(forceBackgroundColor, '0')} 0%, ${
      hexToRGB(forceBackgroundColor, '0.8') ?? 'transparent'
    } 57.21%);` ?? 'transparent'};

  ${mqFrom.L`
    height: 105px;
  `}

  &:before {
    background: ${({ backgroundColor }) =>
      `linear-gradient(0deg, ${hexToRGB(backgroundColor, '0')} 0%, ${
        hexToRGB(backgroundColor, '0.8') ?? 'transparent'
      } 44.21%);` ?? 'transparent'};
  }
`

const TopNav = styled.div`
  padding: 16px;
  position: relative;
  width: 100%;
  z-index: 110;
  display: flex;
  min-height: 68px;
  align-items: center;
  justify-content: space-between;
  ${mqFrom.L`
    display: none;
  `}
`

const TopNavDesktop = styled.div`
  display: none;
  width: 100%;
  align-items: center;
  padding: 8px 32px 0;
  height: 100%;

  ${mqFrom.L`
    display: flex;
  `}
`

const LeftNav = styled.div`
  padding-left: 24px;
  display: grid;
  gap: 3px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  height: 100%;
`

const RightNav = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const DesktopNavLink = styled(Link)`
  ${applyTextStyle('heading3', 'mobile')}
  color: white;
  text-decoration: none;
  padding: 32px 4px;
  display: inline-block;
  min-width: 80px;
  text-align: center;
  border-bottom: ${(props: { selected: boolean }) =>
    props.selected ? '4px solid transparent' : '4px solid transparent'};

  font-weight: ${(props: { selected: boolean }) =>
    props.selected ? 'bold' : 'normal'};

  ${mqFrom.L`
    min-width: 170px;
    min-height: 50px;
    padding: 36px 16px 28px;
  `}
`

const DesktopNavItem = styled.div`
  ${applyTextStyle('heading3', 'mobile')}
  cursor: pointer;
  padding: 32px 4px;
  display: inline-block;
  min-width: 80px;
  text-align: center;
  ${mqFrom.L`
    min-width: 120px;
    padding: 32px 20px;
  `}
`

const Logo = styled.div`
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  span {
    padding-top: 2px;
  }
`

const LogoDesktop = styled(Link)`
  display: block;
`

const RightButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  * {
    margin-right: 14px;
  }

  *:last-child {
    margin: 0;
  }
`

const HamburgerRow = styled.span`
  width: 100%;
  height: 2px;
  background-color: ${colors.white};

  @keyframes TopNavbarAnimationOut {
    0% {
      transform: translateY(6px) rotate(45deg);
    }
    50% {
      transform: translateY(6px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }

  @keyframes MiddleNavbarAnimationOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes BottomNavbarAnimationOut {
    0% {
      transform: translateY(-6px) rotate(-45deg);
    }
    50% {
      transform: translateY(-6px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }
`

const Hamburger = styled.div`
  @keyframes TopNavbarAnimationIn {
    50% {
      height: 3px
      transform: translateY(6px);
    }
    100% {
      height: 4px;
      transform: translateY(6px) rotate(45deg);
    }
  }

  @keyframes MiddleNavbarAnimationIn {
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes BottomNavbarAnimationIn {
    50% {
      height: 3px;
      transform: translateY(-6px);
    }
    100% {
      height: 4px;
      transform: translateY(-6px) rotate(-45deg);
    }
  }

  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 28px;
  height: 16px;
  cursor: pointer;

  ${(props: { open: boolean; openingChanged: boolean }) =>
    props.open
      ? `
    ${HamburgerRow} {
      :first-child {
        animation: TopNavbarAnimationIn ${slowDuration}ms ease-in-out forwards;
      }
      :nth-child(2) {
        animation: MiddleNavbarAnimationIn ${slowDuration}ms ease-in-out forwards;
      }
      :last-child {
        animation: BottomNavbarAnimationIn ${slowDuration}ms ease-in-out forwards;
      }
    }
  `
      : props.openingChanged &&
        `
      ${HamburgerRow} {
        :first-child {
          animation: TopNavbarAnimationOut ${slowDuration}ms ease-in-out forwards;
        }
        :nth-child(2) {
          animation: MiddleNavbarAnimationOut ${slowDuration}ms ease-in-out forwards;
        }
        :last-child {
          animation: BottomNavbarAnimationOut ${slowDuration}ms ease-in-out forwards;
        }
      }
    `}
`

const Menu = styled.div`
  z-index: 90;
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 80px 16px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url(${BackgroundMobile}) center center / cover no-repeat;
  background-color: ${colors.primary2};
  transition: opacity ${slowTransition};
  opacity: ${(props: { open: boolean }) => (props.open ? '1' : '0')};
  pointer-events: ${(props: { open: boolean }) =>
    props.open ? 'auto' : 'none'};
`

const LinkStyling = css`
  ${applyTextStyle('heading1')}

  @keyframes fadeInUp {
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  margin-bottom: 8px;
  text-decoration: none;
  color: ${colors.white};
  transition: color ${fastTransition};
  transform: translateY(16px);
  opacity: 0;

  :hover {
    color: ${colors.white};
  }
`

const NavLink = styled(Link)`
  ${LinkStyling};

  ${(props: { open: boolean }) =>
    props.open &&
    `
    :nth-child(1) {
      animation: fadeInUp ${fastDuration}ms ease-in-out forwards;
    }
    :nth-child(2) {
      animation: fadeInUp ${fastDuration}ms ease-in-out forwards;
      animation-delay: 300ms;
    }
    :nth-child(3) {
      animation: fadeInUp ${fastDuration}ms ease-in-out forwards;
      animation-delay: calc(300ms * 1.5);
    }
    :nth-child(4) {
      animation: fadeInUp ${fastDuration}ms ease-in-out forwards;
      animation-delay: calc(300ms * 2);
    }
    :nth-child(5) {
      animation: fadeInUp ${fastDuration}ms ease-in-out forwards;
      animation-delay: calc(300ms * 2.5);
    }
    :nth-child(6) {
      animation: fadeInUp ${fastDuration}ms ease-in-out forwards;
      animation-delay: calc(300ms * 3);
    }
  `};
`

const BackButton = styled.div`
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
`

const NavSmall = styled.div`
  width: 30%;
`

const NavLogo = styled.div`
  width: 40%;
`

const NavRightButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

const StyledNavButtonButton = styled(Button)`
  max-width: 200px;
  margin-bottom: 24px;
`

const IconStyled = styled(Icon)`
  cursor: pointer;
`

const SmallButton = styled(Button)`
  ${applyTextStyle('smallText', 'mobile')};
  padding: 6px 10px;
`

export default Navigation
