import React from 'react'

import { IStory } from '@monorepo/shared/common/models/story'
import StoryCard from './cards/StoryCard'
import HorizontalScrollList from './templates/HorizontalScrollList'

interface IProps {
  className?: string
  stories: IStory[]
  title?: string
  buttonColor?: string
}

const StoriesList: React.FC<IProps> = ({
  className,
  stories,
  title,
  buttonColor,
}) => {
  return stories.length ? (
    <HorizontalScrollList
      buttonColor={buttonColor}
      className={className}
      title={title || ''}
      items={stories.map((story, i) => (
        <StoryCard story={story} key={'' + i} />
      ))}
    />
  ) : (
    <></>
  )
}

export default StoriesList
