import React from 'react'
import styled from 'styled-components'
import {
  IconArrowDown,
  IconPlay,
  IconArrowLeft,
  IconArrowRight,
  IconLogoLetter,
  IconInstagram,
  IconLogoFacebook,
  IconLogoMail,
  IconHamburger,
  IconLogo,
  IconPause,
  IconFastForward,
  IconFastBackward,
  IconNextEpisode,
  IconPreviousEpisode,
  IconRead,
  IconArrowUp,
  IconSearch,
  IconClose,
  IconEye,
  IconPerson,
  IconInfo,
  IconBag,
  IconFlame,
  IconHeart,
  IconHeartEmpty,
  IconMore,
} from '.'
import colors from '@monorepo/shared/styles/colors'
import { IconType } from './IconType'

interface IProps {
  type: IconType
  size?: number
  width?: number
  height?: number
  color?: string
  className?: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

interface IconProps extends IProps {
  color: string
  size: number
}
interface IconPropsMore extends IProps {
  width: number
  height: number
}

const Icon = (props: IProps) => {
  const size = props.size !== undefined ? props.size : 13
  const isHeightAndWidth: boolean =
    props.width !== undefined && props.height !== undefined ? true : false
  const width = props.width !== undefined ? props.width : size
  const height = props.height !== undefined ? props.height : size
  const color = props.color ? props.color : 'black'
  return (
    <IconWrapper
      className={props.className}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      size={size}
      width={width}
      height={height}
    >
      {props.type === 'ArrowDown' && (
        <IconArrowDown size={size} color={color} />
      )}
      {props.type === 'ArrowLeft' && (
        <IconArrowLeft size={size} color={color} />
      )}
      {props.type === 'ArrowRight' && (
        <IconArrowRight size={size} color={color} />
      )}
      {props.type === 'ArrowUp' && <IconArrowUp size={size} color={color} />}
      {props.type === 'Bag' && <IconBag size={size} color={color} />}
      {props.type === 'Play' && <IconPlay size={size} color={color} />}
      {props.type === 'Heart' && isHeightAndWidth && (
        <IconHeart width={width} height={height} color={color} />
      )}
      {props.type === 'HeartEmpty' && isHeightAndWidth && (
        <IconHeartEmpty width={width} height={height} color={color} />
      )}
      {props.type === 'More' && isHeightAndWidth && (
        <IconMore width={width} height={height} />
      )}
      {props.type === 'Flame' && isHeightAndWidth && (
        <IconFlame
          width={width}
          height={height}
          color={props.color ? props.color : colors.white}
        />
      )}
      {props.type === 'LogoLetter' && isHeightAndWidth && (
        <IconLogoLetter width={width} height={height} color={color} />
      )}
      {props.type === 'Facebook' && isHeightAndWidth && (
        <IconLogoFacebook width={width} height={height} />
      )}
      {props.type === 'Instagram' && isHeightAndWidth && (
        <IconInstagram width={width} height={height} color={color} />
      )}
      {props.type === 'Mail' && isHeightAndWidth && (
        <IconLogoMail width={width} height={height} color={color} />
      )}
      {props.type === 'Hamburger' && isHeightAndWidth && (
        <IconHamburger width={width} height={height} />
      )}
      {props.type === 'Logo' && isHeightAndWidth && (
        <IconLogo width={width} height={height} />
      )}
      {props.type === 'Pause' && <IconPause size={size} color={color} />}
      {props.type === 'FastForward' && (
        <IconFastForward size={size} color={color} />
      )}
      {props.type === 'FastBackward' && (
        <IconFastBackward size={size} color={color} />
      )}
      {props.type === 'NextEpisode' && (
        <IconNextEpisode size={size} color={color} />
      )}
      {props.type === 'PreviousEpisode' && (
        <IconPreviousEpisode size={size} color={color} />
      )}
      {props.type === 'Read' && <IconRead size={size} color={color} />}
      {props.type === 'Search' && <IconSearch size={size} color={color} />}
      {props.type === 'Close' && <IconClose size={size} color={color} />}
      {props.type === 'Eye' && <IconEye size={size} color={color} />}
      {props.type === 'Person' && <IconPerson size={size} color={color} />}
      {props.type === 'Info' && <IconInfo size={size} color={color} />}
    </IconWrapper>
  )
}

export const IconWrapper = styled.span<
  Pick<IconProps | IconPropsMore, 'size' | 'width' | 'height'>
>`
  position: relative;
  display: inline-block;
  width: ${(props) => (props.width == undefined ? props.size : props.width)}px;
  height: ${(props) =>
    props.height == undefined ? props.size : props.height}px;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;

    path {
      transition: fill 0.3s ease;
    }
  }
`

export default Icon
