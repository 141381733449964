import { css, FlattenSimpleInterpolation } from 'styled-components'
import { IGridConfig } from '@monorepo/shared/common/models/grid'

interface ISizes {
  [index: string]: number
}

// these sizes are arbitrary and you can set them to whatever you wish
const sizes: ISizes = {
  XS: 320,
  S: 544,
  M: 768,
  L: 1024,
  XL: 1280,
}

export enum Breakpoint {
  XS = sizes.XS,
  S = sizes.S,
  M = sizes.M,
  L = sizes.L,
  XL = sizes.XL,
}

export const gridConfig: IGridConfig = {
  breakpoints: [
    // Note: these may not correspond w/ already defined breakpoints
    // eg. the 'S' breakpoint is not needed in the grid
    {
      name: 'xs',
      startsFrom: Breakpoint.XS,
      gutterWidth: 16,
      maxColumns: 4,
    },
    {
      name: 'm',
      startsFrom: Breakpoint.M,
      gutterWidth: 20,
      maxColumns: 12,
    },
    {
      name: 'l',
      startsFrom: Breakpoint.L,
      gutterWidth: 24,
      maxColumns: 12,
    },
  ],
}

// iterate through the sizes and create a media template
const mqFrom = Object.keys(sizes).reduce(
  (mqObject, breakPointLabel) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    const emSize = sizes[breakPointLabel] / 16

    mqObject[breakPointLabel] = (
      cssStrings: TemplateStringsArray,
      breakpointSize: string
    ) => css`
      @media (min-width: ${emSize}em) {
        ${css(cssStrings, breakpointSize)}
      }
    `
    return mqObject
  },
  {
    px:
      (px: number) =>
      (cssStrings: TemplateStringsArray, breakpointSize: string) =>
        css`
          @media (min-width: ${px}px) {
            ${css(cssStrings, breakpointSize)}
          }
        `,
  } as { [key: string]: any }
)

// iterate through the sizes and create a media template
const mqTo = Object.keys(sizes).reduce(
  (mqObject, breakPointLabel) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    const emSize = (sizes[breakPointLabel] - 1) / 16

    mqObject[breakPointLabel] = (
      cssStrings: TemplateStringsArray,
      breakpointSize: string
    ) => css`
      @media (max-width: ${emSize}em) {
        ${css(cssStrings, breakpointSize)};
      }
    `
    return mqObject
  },
  {
    px:
      (px: number) =>
      (cssStrings: TemplateStringsArray, breakpointSize: string) =>
        css`
          @media (max-width: ${px}px) {
            ${css(cssStrings, breakpointSize)};
          }
        `,
  } as { [key in keyof ISizes]: any }
)

export interface IScreenRange {
  from?: Breakpoint
  to?: Breakpoint
}

export interface IScreenRanges {
  xs: IScreenRange
  s: IScreenRange
  m: IScreenRange
  l: IScreenRange
  xl: IScreenRange
}

// A screen range defines a range throughout which the styles stay the same
export const screenRanges: IScreenRanges = {
  xs: { from: 0, to: Breakpoint.S },
  s: { from: Breakpoint.S, to: Breakpoint.M },
  m: { from: Breakpoint.M, to: Breakpoint.L },
  l: { from: Breakpoint.L, to: Breakpoint.XL },
  xl: { from: Breakpoint.XL },
}

/**
 * Create the needed CSS for a given media query (works like a mixin)
 * @param range The range to use (passed styles will only be applied if screen size corresponds to this range)
 * @param styles The styles to conditionally apply
 */
const applyResponsive = (
  range: IScreenRange,
  styles: FlattenSimpleInterpolation | string
) => {
  let mediaQuery = '@media screen'
  // Conditionally build the media query declaration, based on the set limits
  if (range.from && range.to) {
    mediaQuery += ` and
      (min-width: ${range.from / 16}em) and
      (max-width: ${range.to / 16}em)`
  } else if (range.from && !range.to) {
    mediaQuery += ` and
      (min-width: ${range.from / 16}em)`
  } else if (!range.from && range.to) {
    mediaQuery += ` and
      (max-width: ${range.to / 16}em)`
  }

  return css`
    ${mediaQuery} {
      ${styles}
    }
  `
}

export { sizes, mqFrom, mqTo, applyResponsive }
