import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrowUp = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 34 22"
    fill={props.color}
  >
    <path
      d="M30.3427 20.4737C29.7759 21.0405 28.8569 21.0405 28.2901 20.4737L13.9221 6.10569C13.3553 5.53889 13.3553 4.61992 13.9221 4.05312L15.9747 2.00055C16.5415 1.43374 17.4604 1.43374 18.0273 2.00055L32.3953 16.3686C32.9621 16.9354 32.9621 17.8543 32.3953 18.4211L30.3427 20.4737Z"
      fill="white"
    />
    <path
      d="M20.0167 4.05379C20.5835 4.62059 20.5835 5.53956 20.0167 6.10636L5.64867 20.4744C5.08187 21.0412 4.1629 21.0412 3.59609 20.4744L1.54352 18.4218C0.976718 17.855 0.976718 16.936 1.54352 16.3692L15.9115 2.00122C16.4783 1.43442 17.3973 1.43442 17.9641 2.00122L20.0167 4.05379Z"
      fill="white"
    />
  </svg>
)

export default IconArrowUp
