import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconPause = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 10 14"
    fill="none"
  >
    <rect width="3" height="14" rx="1.5" fill={props.color} />
    <rect x="7" width="3" height="14" rx="1.5" fill={props.color} />
  </svg>
)

export default IconPause
