import React from 'react'
import styled from 'styled-components'
import { IOptions } from '@monorepo/shared/common/models/options'
import colors from '@monorepo/shared/styles/colors'
import { mqFrom, mqTo } from '@monorepo/shared/styles/responsive'
import { applyTextStyle } from '@monorepo/shared/styles/typography'
import Button from '@monorepo/shared/components/Button'
import { useAppState, useAppDispatch } from '../../context/AppContext'
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from 'react-share'

interface IProps {
  options: IOptions
}

const OptionsCard: React.FC<IProps> = () => {
  const appState = useAppState()
  const appDispatch = useAppDispatch()

  const close = () => {
    appDispatch({
      type: 'updateOptionsPopup',
      payload: undefined,
    })
  }

  return (
    <OptionsComponent>
      <Wrapper onClick={close}></Wrapper>
      <Popup
        top={appState.optionsPopup?.coordinates.y || 0}
        left={(appState.optionsPopup?.coordinates.x || 280) - 280}
      >
        <RowContainer>{generateRows()}</RowContainer>
        <CancelDiv>
          <Button
            variant={'secondary'}
            caption={'Annuleer'}
            onClick={close}
          ></Button>
        </CancelDiv>
      </Popup>
    </OptionsComponent>
  )
}

function generateRows() {
  const content = []

  const copyUrl = () => {
    const url = window.location.href
    navigator.clipboard.writeText(url)
  }

  const shareList = [
    <WhatsappShareButton url={window.location.href} key="share-on-whatsapp">
      Deel via Whatsapp
    </WhatsappShareButton>,
    <TelegramShareButton url={window.location.href} key="share-on-telegram">
      Deel via Telegram
    </TelegramShareButton>,
    <FacebookMessengerShareButton
      url={window.location.href}
      key="share-on-messenger"
      appId="1"
    >
      Deel via Messenger
    </FacebookMessengerShareButton>,
    <FacebookShareButton url={window.location.href} key="share-on-facebook">
      Deel via Facebook
    </FacebookShareButton>,
    <EmailShareButton
      url={window.location.href}
      key="share-on-email"
      onClick={copyUrl}
    >
      Kopieer naar klembord
    </EmailShareButton>,
  ]

  for (let i = 0; i < shareList.length; i++) {
    content.push(
      <Col key={'row:' + i}>
        <Content>
          <Text>{shareList[i]}</Text>
        </Content>
      </Col>
    )
  }
  return content
}

const OptionsComponent = styled.div`
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 300;
  ${mqFrom.L`
    position: absolute;
    height: 100%;
  `}
`

const Wrapper = styled.div`
  background: ${colors.bkgdDisNonhoveredBlur}};
  height: 100%;
  backdrop-filter: blur(15px);
  left: 0;
  top: 0;
  width: 100%;
  z-index: 310;
  position: absolute;
  ${mqFrom.L`
    background: transparent;
    backdrop-filter: none;
    z-index: 50;
  `}
`

const Text = styled.a`
  ${applyTextStyle('body', 'mobile')}
  color: ${colors.black};
`

const CancelDiv = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  ${mqFrom.L`
    display: none;
  `}
`

const Popup = styled.div<{ top: number; left: number }>`
  width: 80vw;
  margin: 0 auto;
  left: ${({ left }) => `${left}px`};
  top: ${({ top }) => `${top}px`};
  z-index: 320;
  ${mqTo.L`
    position: absolute;
    bottom: 0;
    top: initial;
    left: 10vw;
  `}
  ${mqFrom.L`
    position: absolute;
    transform: 0;
    -webkit-transform: none;
    transform: none;
    width: 280px;
    z-index: 60;
  `}
`

const RowContainer = styled.div`
  width: 100%;
  background-color: ${colors.white};
  border-radius: 4px;
  margin-bottom: 12px;
`

const Col = styled.div`
  justify-items: center;
`

const Content = styled.div`
  padding: 10px 24px;
`

export default OptionsCard
