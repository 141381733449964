import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import AppContainer from '@monorepo/shared/components/AppContainer'
import Slider from './Slider'
import { mqFrom } from '@monorepo/shared/styles/responsive'
import {
  applyCustomTextStyle,
  applyTextStyle,
} from '@monorepo/shared/styles/typography'
import ControlPanel from './ControlPanel'
import Button from '@monorepo/shared/components/Button'
import { IPlayer } from '@monorepo/shared/common/models/player'
import colors from '@monorepo/shared/styles/colors'
import { Icon } from '@monorepo/shared/components/icon/'
import BgImage from '@monorepo/shared/components/BgImage'
import {
  FontFamily,
  FontWeight,
} from '@monorepo/shared/common/models/typography'
import { navigate } from 'gatsby-link'
import { useAppDispatch, useAppState } from '../context/AppContext'
import { Link } from 'gatsby'
import { generateRating } from '../dynamic-pages/series'
import { getImage } from 'gatsby-plugin-image'
import {
  addAmplitudeUserProperty,
  sendAmplitudeData,
} from '@monorepo/shared/utils/amplitude.helper'
import scrollHelper from '@monorepo/shared/common/helpers/scroll.helper'
import { isStoryWithEpisodesWithDescriptions } from '@monorepo/shared/common/models/story'
import addStoryToHistoryService from '../context/services/addStoryToHistory.service'
import staticContent from '../constants/staticContent'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import { getEpisodePreviewLength } from '@monorepo/shared/utils/dates.helper'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

interface IProps {
  content: IPlayer
}

const Player: React.FC<IProps> = ({ content }) => {
  const appState = useAppState()
  const appDispatch = useAppDispatch()

  // Either user is subscribed AND not in trial, OR the user is in trial and this story is accessible for people in trial
  const isPaid = !!(
    (appState.user?.subscription?.subscriptionPlan &&
      !appState.user?.subscription?.isTrialing) ||
    (appState.user?.subscription?.isTrialing &&
      content.story.data.available_during_trial)
  )

  const [percentage, setPercentage] = useState(0)

  const audioRef = useRef<HTMLAudioElement>(null)
  const audio = audioRef.current
  const isPlaying = appState.player?.isPlaying
  const selectedEpisode = appState.player?.selectedEpisode

  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState<number>(0)
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
  const [listenedFired, setListenedFired] = useState<boolean>(false)

  // Invoked when the user clicks on the slider and changes to a different location of the audio
  const onChangeOfTimeUsingSlider = (
    e: React.SyntheticEvent<HTMLInputElement, Event>
  ) => {
    const target = e.target as HTMLInputElement
    if (audio) {
      audio.currentTime = (audio.duration / 100) * Number(target.value)
    }
    setPercentage(Number(target.value))
  }

  // Invoked when the user clicks the play button of the control panel
  const playButtonClick = () => {
    if (!isPlaying && audio) {
      appDispatch({
        type: 'updatePlayer',
        payload: { ...content, isPlaying: true },
      })
    }

    if (isPlaying && audio) {
      appDispatch({
        type: 'updatePlayer',
        payload: { ...content, isPlaying: false },
      })
    }
  }

  // Makes sure that the audio is always playing if the state says so
  useEffect(() => {
    if (audio && isPlaying && !audio.paused) {
      setTimeout(() => {
        audio.play()
      }, 50)
    }
  }, [content.story.slug, content.selectedEpisode, content.story, audio])

  // Used to register listening events
  useEffect(() => {
    if (audio) {
      isPlaying ? audio.play() : audio.pause()
    }
  }, [isPlaying])

  // Reset player always when episode changes
  useEffect(() => {
    setCurrentTime(0)
    setPercentage(0)
    setListenedFired(false)
  }, [selectedEpisode])

  // Reset player always when story changes
  useEffect(() => {
    setCurrentTime(0)
    setPercentage(0)
    setListenedFired(false)
  }, [content.story])

  // Keep track of time played
  useEffect(() => {
    if (audio && isPaid) {
      const interval = setInterval(() => {
        const totalTime = getTotalTimePlayed()
        if (totalTime > audio.duration * 0.75 && !listenedFired) {
          setListenedFired(true)
          sendAmplitudeData('AudiostoryCompleted', {
            Audiostory_ID: content.story.slug,
            Audiostory_Name: content.story.data.title.text,
            Episode_Name:
              content.story.data.episodes[selectedEpisode || 0] &&
              content.story.data.episodes[selectedEpisode || 0].title.text,
          })
          addAmplitudeUserProperty('total_audiostories_finished', 1)
        }
      }, 1500)

      return () => {
        clearInterval(interval)
      }
    }
  }, [audio, isPaid, listenedFired])

  const getTotalTimePlayed = () => {
    if (!audio) return 0
    let totalTimePlayed = 0
    for (let i = 0; i < audio.played.length; i++) {
      // Logs something like:  0, 5.3333
      totalTimePlayed += audio.played.end(i) - audio.played.start(i)
    }
    return totalTimePlayed
  }

  // Inform amplutude that a story is started
  const registerListeningHistory = () => {
    sendAmplitudeData(isPaid ? 'AudiostoryStarted' : 'PreviewStarted', {
      Audiostory_ID: content.story.slug,
      Audiostory_Name: content.story.data.title.text,
      Episode_Name:
        content.story.data.episodes[selectedEpisode || 0] &&
        content.story.data.episodes[selectedEpisode || 0].title.text,
    })

    if (
      appState.user &&
      appState.user.data.lastPlayedStory !== content.story.slug
    ) {
      // This story is different than the last one the user listened to
      // Thus, send in the backend to be kept in the history
      appDispatch({
        type: 'updateUser',
        payload: {
          ...appState.user,
          data: {
            ...appState.user.data,
            lastPlayedStory: content.story.slug,
          },
        },
      })
      addStoryToHistoryService(
        appState.userAuthToken || '',
        content.story.slug
      ).catch((e) => {
        console.info(e)
        return
      })
    }
  }

  const changeEpisode = (isNextEpisode: boolean) => {
    if (selectedEpisode !== undefined) {
      appDispatch({
        type: 'updatePlayer',
        payload: {
          ...content,
          selectedEpisode: isNextEpisode
            ? selectedEpisode + 1
            : selectedEpisode - 1,
        },
      })
    }
  }

  const onPlayerPlay = () => {
    appDispatch({
      type: 'updatePlayer',
      payload: { ...content, isPlaying: true },
    })
    registerListeningHistory()
  }

  const onPlayerPause = () => {
    appDispatch({
      type: 'updatePlayer',
      payload: { ...content, isPlaying: false },
    })
  }

  const getCurrentDuration = (
    e: React.SyntheticEvent<HTMLAudioElement, Event>
  ) => {
    const percent =
      (e.currentTarget.currentTime / e.currentTarget.duration) * 100
    const time = e.currentTarget.currentTime

    setPercentage(+percent)
    setCurrentTime(+time.toFixed(2))
  }

  const moveTenSeconds = (isForward: boolean) => {
    if (audio) {
      if (isForward) {
        audio.currentTime += 10
        setCurrentTime(currentTime + 10)
      } else {
        audio.currentTime -= 10
        setCurrentTime(currentTime - 10)
      }
    }
  }

  const onEnd = () => {
    const currentEpisode = content.selectedEpisode
    const currentStory = content.story

    if (currentEpisode < currentStory.data.episodes.length - 1) {
      appDispatch({
        type: 'updatePlayer',
        payload: { ...content, selectedEpisode: currentEpisode + 1 },
      })
    } else {
      appDispatch({
        type: 'updatePlayer',
        payload: { ...content, isPlaying: false },
      })
    }
  }

  const isSingleStory = content.story.data.episodes?.length === 1
  const serieName = content.story.data.title.text
  const episodes = content.story.data.episodes || []
  // const isAudioPlayer = content.story.story_type === 'audio'
  const isAudioPlayer = !appState.player?.isReading
  const { hot_part_start, hot_part_end } =
    selectedEpisode !== undefined &&
    selectedEpisode < episodes.length &&
    appState.user?.subscription
      ? episodes[selectedEpisode ?? 0]
      : {
          hot_part_start: undefined,
          hot_part_end: undefined,
        }

  useEffect(() => {
    if (!isFullScreen && !isAudioPlayer) {
      setIsFullScreen(true)
    }
  }, [isAudioPlayer, appState.player?.story])

  useEffect(() => {
    if (isFullScreen) {
      // alert('going full screen')
      scrollHelper.enable()
      // document.getElementsByTagName('html')[0].style.overflow = 'hidden'
    } else {
      // document.getElementsByTagName('html')[0].style.overflow = 'initial'
      scrollHelper.disable()
      clearAllBodyScrollLocks()
    }
  }, [isFullScreen])

  return (
    <>
      {selectedEpisode !== undefined && (
        <>
          <StyledPlayer isFullScreen={isFullScreen}>
            <StyledIcon
              isFullScreen={isFullScreen}
              color={colors.neutralWhite}
              type="ArrowUp"
              onClick={() => setIsFullScreen(!isFullScreen)}
            />
            {isFullScreen && (
              <AppContainerStyled isAudioPlayer={isAudioPlayer}>
                <EpisodeImageWrapper>
                  <EpisodeImage isFullScreen={isFullScreen}>
                    <GatsbyImage
                      alt="story cover"
                      imgStyle={{ objectFit: 'contain' }}
                      image={
                        getImage(content.story.data.cover) as IGatsbyImageData
                      }
                    />
                    <StoryFlames>
                      {generateRating(
                        +(content.story.data.level_of_intensity ?? 0)
                      )}
                    </StoryFlames>
                  </EpisodeImage>
                </EpisodeImageWrapper>
                <PlayerBottomContent
                  isAudioStory={isAudioPlayer}
                  isPaid={isPaid}
                >
                  <PlayerContainer>
                    {!isSingleStory && (
                      <SeriesName>serie: {serieName}</SeriesName>
                    )}
                    <EpisodeTitle>
                      {isSingleStory
                        ? content.story.data.episodes[selectedEpisode].title
                            .text
                        : selectedEpisode +
                          1 +
                          '. ' +
                          content.story.data.episodes[selectedEpisode].title
                            .text}
                    </EpisodeTitle>
                    <Author
                      to={`/authors/${content.story.data.authors[0].author.document?.slug}`}
                      onClick={() => setIsFullScreen(false)}
                    >
                      {(content.story.data.authors.length &&
                        content.story.data.authors[0].author.document?.data.name
                          .text) ||
                        'No author'}
                    </Author>
                  </PlayerContainer>
                  {isAudioPlayer && isPlaying !== undefined && (
                    <PlayerContainer>
                      <SliderContainer>
                        <Slider
                          duration={duration}
                          currentTime={currentTime}
                          onChange={onChangeOfTimeUsingSlider}
                          percentage={percentage}
                          hotPartFrom={hot_part_start}
                          hotPartTo={hot_part_end}
                        />
                      </SliderContainer>
                      <ControlPanel
                        isSingleStory={isSingleStory}
                        isPlaying={isPlaying}
                        play={playButtonClick}
                        moveTenSecondsBackward={() => moveTenSeconds(false)}
                        moveTenSecondsForward={() => moveTenSeconds(true)}
                        isFullScreen={isFullScreen}
                        playPreviousEpisode={
                          isSingleStory ? undefined : () => changeEpisode(false)
                        }
                        playNextEpisode={
                          isSingleStory ? undefined : () => changeEpisode(true)
                        }
                        isPreviousEpisodeDisabled={
                          isSingleStory ? undefined : selectedEpisode === 0
                        }
                        isNextEpisodeDisabled={
                          isSingleStory
                            ? undefined
                            : selectedEpisode ===
                              content.story.data.episodes.length - 1
                        }
                      />
                    </PlayerContainer>
                  )}
                  {!isAudioPlayer &&
                    isStoryWithEpisodesWithDescriptions(content.story) && (
                      <>
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: isPaid
                              ? content.story.data.episodes[selectedEpisode]
                                  .story?.html ?? ''
                              : content.story.data.episodes[selectedEpisode]
                                  .preview_story?.html ?? '',
                          }}
                        ></Text>
                        {!isSingleStory && (
                          <TextControlPanel>
                            <Button
                              disabled={selectedEpisode === 0}
                              caption={staticContent.player.previous}
                              onClick={() => changeEpisode(false)}
                            />
                            <Button
                              disabled={
                                selectedEpisode ===
                                content.story.data.episodes.length - 1
                              }
                              caption={staticContent.player.next}
                              onClick={() => changeEpisode(true)}
                            />
                          </TextControlPanel>
                        )}
                      </>
                    )}
                </PlayerBottomContent>
              </AppContainerStyled>
            )}
            {!isFullScreen && (
              <>
                <PlayerLeftContent>
                  <EpisodeImage isFullScreen={isFullScreen}>
                    <Link to={`/series/${content.story.slug}`}>
                      <BgImage image={getImage(content.story.data.cover)} />
                    </Link>
                  </EpisodeImage>
                  <SmallScreenTexts>
                    <SmallScreenEpisodeTitle>
                      <Link to={`/series/${content.story.slug}`}>
                        {
                          content.story.data.episodes[selectedEpisode].title
                            .text
                        }
                        {!isSingleStory
                          ? ' ' +
                            (selectedEpisode + 1) +
                            '/' +
                            content.story.data.episodes.length
                          : null}
                      </Link>
                    </SmallScreenEpisodeTitle>
                    <SmallScreenAuthor>
                      {(content.story.data.authors.length &&
                        content.story.data.authors[0].author.document?.data.name
                          .text) ||
                        'Unknown'}
                    </SmallScreenAuthor>
                  </SmallScreenTexts>
                </PlayerLeftContent>
                <SmallScreenPlayerWrapper>
                  <SmallScreenPlayerContainer>
                    {isAudioPlayer && (
                      <SmallScreenSliderContainer>
                        <Slider
                          hideTumb={true}
                          duration={duration}
                          currentTime={currentTime}
                          onChange={onChangeOfTimeUsingSlider}
                          percentage={percentage}
                          hotPartFrom={hot_part_start}
                          hotPartTo={hot_part_end}
                        />
                      </SmallScreenSliderContainer>
                    )}
                    {isAudioPlayer && isPlaying !== undefined && (
                      <ControlPanel
                        isSingleStory={isSingleStory}
                        isPlaying={isPlaying}
                        play={playButtonClick}
                        moveTenSecondsBackward={() => moveTenSeconds(false)}
                        moveTenSecondsForward={() => moveTenSeconds(true)}
                        isFullScreen={isFullScreen}
                        playPreviousEpisode={
                          isSingleStory ? undefined : () => changeEpisode(false)
                        }
                        playNextEpisode={
                          isSingleStory ? undefined : () => changeEpisode(true)
                        }
                        isPreviousEpisodeDisabled={
                          isSingleStory ? undefined : selectedEpisode === 0
                        }
                        isNextEpisodeDisabled={
                          isSingleStory
                            ? undefined
                            : selectedEpisode === episodes.length - 1
                        }
                      />
                    )}
                  </SmallScreenPlayerContainer>
                </SmallScreenPlayerWrapper>
                <PlayerRightEmptyContent></PlayerRightEmptyContent>
                <StyledPlayerIcon
                  onClick={
                    isAudioPlayer
                      ? playButtonClick
                      : () => setIsFullScreen(true)
                  }
                  type={isAudioPlayer ? (isPlaying ? 'Pause' : 'Play') : 'Read'}
                  size={24}
                  color={colors.neutralWhite}
                />
                <button
                  className="hidden-button"
                  onClick={() => setIsFullScreen(true)}
                ></button>
                {(audio?.readyState ?? 0) < 1 && isAudioPlayer && (
                  <LoadingBox>...</LoadingBox>
                )}
              </>
            )}
            {isAudioPlayer && (
              <audio
                ref={audioRef}
                src={
                  isPaid
                    ? episodes[selectedEpisode].sound_file?.url
                    : episodes[selectedEpisode].preview_sound_file?.url
                }
                onLoadedData={(e) => {
                  setDuration(+e.currentTarget.duration.toFixed(2))
                }}
                onTimeUpdate={getCurrentDuration}
                onPlay={onPlayerPlay}
                onPause={onPlayerPause}
                onEnded={onEnd}
                autoPlay={true}
              ></audio>
            )}
          </StyledPlayer>
          {!isPaid && (
            <PreviewAlertContainer isFullScreen={isFullScreen}>
              <PreviewAlertWrapper>
                <PreviewAlertText>
                  Dit is een preview
                  {isAudioPlayer
                    ? ` van ${getEpisodePreviewLength(audio?.duration || 15)}`
                    : ''}
                  .{' '}
                  <PreviewAlertTextBold>
                    {appState.user?.subscription?.isTrialing
                      ? 'Sla proefperiode over voor volledige toegang'
                      : 'Abonneer voor de volledige ervaring.'}
                  </PreviewAlertTextBold>
                </PreviewAlertText>
                <PreviewAlertButton
                  onClick={() => {
                    setIsFullScreen(false)
                    navigate(
                      appState.user ? '/profile/subscription' : '/subscription'
                    )
                  }}
                  icon="ArrowRight"
                />
              </PreviewAlertWrapper>
            </PreviewAlertContainer>
          )}
        </>
      )}
    </>
  )
}

const AppContainerStyled = styled(AppContainer)<{ isAudioPlayer: boolean }>`
  padding: 0 16px 80px 16px;
  max-width: 480px;
  margin: 0 auto;
  ${({ isAudioPlayer }) =>
    isAudioPlayer &&
    css`
      height: 100%;
    `}
`

const StyledPlayer = styled.div<{
  isFullScreen: boolean
}>`
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: ${colors.primary2};
  left: 0;
  right: 0;
  bottom: 0;

  ${({ isFullScreen }) =>
    isFullScreen
      ? css`
          height: 100%;
          overflow-y: auto;
        `
      : css`
          display: flex;
          height: 56px;
          bottom: 0px;
          .hidden-button {
            width: calc(100% - 130px);
            left: 0;
            height: 100%;
            position: absolute;
            background: transparent;
            border: none !important;
            font-size: 0;
            cursor: pointer;
          }
          ${mqFrom.L`
            height: 96px;
            .hidden-button {
              display: none;
            }
          `}
        `}
  transition: 0.3s;
`

const StyledIcon = styled(Icon)<{ isFullScreen: boolean }>`
  position: absolute;
  cursor: pointer;
  transition: transform 0.3s ease;
  z-index: 300;

  ${({ isFullScreen }) =>
    isFullScreen
      ? css`
          position: fixed;
          z-index: 1000;
          top: 25px;
          left: 25px;
          transform: rotate(180deg);
          svg {
            width: 25px !important;
            height: 25px !important;
          }
          ${mqFrom.L`
            top: 40px;
            right: 10%;
            left: unset;
            svg {
              width: 30px !important;
              height: 30px !important;
            }
          `}
        `
      : css`
          display: none;
          ${mqFrom.L`
            display: block;
            top: 29px;
            right: 10%;
            svg {
              width: 30px !important;
              height: 30px !important;
            }
          `}
        `}
`

const PlayerLeftContent = styled.div`
  width: 80%;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  ${mqFrom.L`
    white-space: unset;
    width: 33%;
    padding-right: 24px;
  `}
`

const EpisodeImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 16px 0;
  min-height: 60vh;
`
const EpisodeImage = styled.div<{ isFullScreen: boolean }>`
  display: block;
  position: relative;
  ${({ isFullScreen }) =>
    !isFullScreen &&
    css`
      width: 56px;
      flex: 0 0 56px;
      height: 56px;
      ${mqFrom.L`
        width: 96px;
        height: 96px;
        flex: 0 0 96px;
      `};
    `}
`

const PlayerBottomContent = styled.div<{
  isAudioStory: boolean
  isPaid: boolean
}>`
  position: ${({ isAudioStory }) => (isAudioStory ? 'absolute' : 'relative')};
  padding: ${({ isAudioStory, isPaid }) =>
    isAudioStory
      ? `28px 16px ${isPaid ? '20px' : '80px'}`
      : `0 0 ${isPaid ? '20px' : '80px'}`};
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgb(0, 30, 20),
    rgba(0, 30, 20, 0.8) 65%,
    rgba(0, 30, 20, 0.7) 80%,
    rgba(0, 30, 20, 0.1) 95%,
    transparent
  );
  margin: 0 auto;
  margin-top: ${({ isAudioStory }) => (isAudioStory ? '0' : '22px')};
  ${mqFrom.XL`
    padding-bottom: 100px;
  `}
`

const SeriesName = styled.div`
  ${applyTextStyle('label')}
  text-transform: uppercase;
  margin: 0 0 4px 0px;
`

const EpisodeTitle = styled.div`
  ${applyTextStyle('heading1', 'mobile')};
  ${applyTextStyle('headline1', 'desktop')};
  margin: 0 0 8px 0px;
`

const Author = styled(Link)`
  ${applyTextStyle('link')}
  text-decoration-line: underline;
  margin: 0 0 16px 0px;
`

const SliderContainer = styled.div`
  margin-bottom: 24px;
  ${mqFrom.S`
    margin-bottom: 16px;
  `}
`

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Text = styled.div`
  ${applyTextStyle('storyText', 'mobile')};
  ${applyTextStyle('storyParagraph', 'desktop')};
  p {
    ${applyTextStyle('storyText', 'mobile')};
    ${applyTextStyle('storyParagraph', 'desktop')};
  }
  margin: 0 auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const TextControlPanel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 125px;
`

const SmallScreenTexts = styled.div`
  margin-left: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${mqFrom.L`
    margin-left: 16px;
    margin-right: 24px;
  `}
`
const SmallScreenPlayerWrapper = styled.div`
  display: none;

  ${mqFrom.L`
    display: block;
    margin-top: 10px;
    width: 34%;
  `}
`

const PlayerRightEmptyContent = styled.div`
  ${mqFrom.L`
    display: block;
    margin-top: 10px;
    width: 33%;
  `}
`

const SmallScreenPlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 470px;
  margin: 0 auto;
`

const SmallScreenSliderContainer = styled.div`
  margin-bottom: 5px;
`

const StyledPlayerIcon = styled(Icon)`
  position: absolute;
  top: 18px;
  right: 14px;
  cursor: pointer;

  ${mqFrom.L`
    display: none;
  `}
`

const SmallScreenEpisodeTitle = styled.div`
  ${applyTextStyle('heading5', 'mobile')};
  ${applyTextStyle('smallText', 'desktop')};

  ${mqFrom.L`
    margin-bottom: 4px;
  `}
`

const SmallScreenAuthor = styled.div`
  ${applyTextStyle('heading5', 'mobile')};
  ${applyTextStyle('smallText', 'desktop')};
  color: ${colors.neutralGray};
`

const PreviewAlertContainer = styled.div<{ isFullScreen: boolean }>`
  z-index: 1000;
  height: 54px;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s;
  padding: 10px 16px;
  ${({ isFullScreen }) =>
    isFullScreen
      ? css`
          background-color: ${colors.primary1};
        `
      : css`
          background-color: ${colors.primary3};
          bottom: 56px;
          ${mqFrom.L`
            bottom: 96px;
          `}
        `}
`

const PreviewAlertWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-self: center;
  max-width: 300px;
  margin: 0 auto;
  ${mqFrom.M`
    justify-content: center;
    max-width: unset;
    & > *:first-child {
      margin-right: 22px;
    }
  `}
`

const StoryFlames = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
`

const PreviewAlertText = styled.div`
  ${applyCustomTextStyle({
    mobile: {
      fontSize: 13,
      lineHeight: 16,
      fontWeight: FontWeight.Medium,
      fontFamily: FontFamily.SFPro,
      letterSpacing: 0.01,
    },
    desktop: {
      fontSize: 18,
      lineHeight: 16,
      fontWeight: FontWeight.Medium,
      fontFamily: FontFamily.SFPro,
      letterSpacing: 0.01,
    },
  })}
`

const PreviewAlertTextBold = styled.div`
  font-weight: bold;
  ${mqFrom.L`
    display: inline;
  `}
`

const PreviewAlertButton = styled(Button)`
  height: 34px;
  width: 34px;
`

const LoadingBox = styled.div`
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: calc(100% + 5px);
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 40%;
  z-index: 50;
  ${mqFrom.L`
    padding-left: 0;
    align-items: flex-start;
  `}
`

export default Player
