import { HISTORY } from '../../constants/api.constants'
import errorHandler from './errorHandler'

export default (token: string, slug: string) =>
  fetch(HISTORY(), {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      slug,
    }),
  })
    .then(errorHandler)
    .then((): boolean => {
      return true
    })
