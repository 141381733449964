import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconRead = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 35 27"
    fill="none"
  >
    <path
      d="M33.3438 0.131837H24.6297C22.7597 0.131837 20.9315 0.668849 19.3586 1.68193L17.5 2.87402L15.6414 1.68193C14.07 0.669043 12.2399 0.130838 10.3703 0.131837H1.65625C0.982129 0.131837 0.4375 0.676466 0.4375 1.35059V22.9834C0.4375 23.6575 0.982129 24.2022 1.65625 24.2022H10.3703C12.2403 24.2022 14.0685 24.7392 15.6414 25.7522L17.3324 26.8415C17.3819 26.872 17.4391 26.891 17.4962 26.891C17.5533 26.891 17.6104 26.8758 17.66 26.8415L19.351 25.7522C20.9277 24.7392 22.7597 24.2022 24.6297 24.2022H33.3438C34.0179 24.2022 34.5625 23.6575 34.5625 22.9834V1.35059C34.5625 0.676466 34.0179 0.131837 33.3438 0.131837ZM10.3703 21.46H3.17969V2.87402H10.3703C11.7186 2.87402 13.0287 3.25869 14.1599 3.98613L16.0185 5.17822L16.2812 5.34961V22.9453C14.4684 21.9703 12.4422 21.46 10.3703 21.46ZM31.8203 21.46H24.6297C22.5578 21.46 20.5316 21.9703 18.7188 22.9453V5.34961L18.9815 5.17822L20.8401 3.98613C21.9713 3.25869 23.2814 2.87402 24.6297 2.87402H31.8203V21.46ZM13.1163 7.74902H6.03994C5.89141 7.74902 5.76953 7.87852 5.76953 8.03467V9.74854C5.76953 9.90469 5.89141 10.0342 6.03994 10.0342H13.1125C13.261 10.0342 13.3829 9.90469 13.3829 9.74854V8.03467C13.3867 7.87852 13.2648 7.74902 13.1163 7.74902ZM21.6133 8.03467V9.74854C21.6133 9.90469 21.7352 10.0342 21.8837 10.0342H28.9562C29.1048 10.0342 29.2267 9.90469 29.2267 9.74854V8.03467C29.2267 7.87852 29.1048 7.74902 28.9562 7.74902H21.8837C21.7352 7.74902 21.6133 7.87852 21.6133 8.03467ZM13.1163 13.0811H6.03994C5.89141 13.0811 5.76953 13.2105 5.76953 13.3667V15.0806C5.76953 15.2367 5.89141 15.3662 6.03994 15.3662H13.1125C13.261 15.3662 13.3829 15.2367 13.3829 15.0806V13.3667C13.3867 13.2105 13.2648 13.0811 13.1163 13.0811ZM28.9601 13.0811H21.8837C21.7352 13.0811 21.6133 13.2105 21.6133 13.3667V15.0806C21.6133 15.2367 21.7352 15.3662 21.8837 15.3662H28.9562C29.1048 15.3662 29.2267 15.2367 29.2267 15.0806V13.3667C29.2305 13.2105 29.1086 13.0811 28.9601 13.0811Z"
      fill={props.color}
    />
  </svg>
)

export default IconRead
