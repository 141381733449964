import { navigate } from 'gatsby'

export const onlyForNotAuthenticated = (appState: any) => {
  if (appState.userAuthToken) {
    navigate('/profile/account')
  }
}

export const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => {
  return value !== null && value !== undefined
}
