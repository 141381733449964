import { createGlobalStyle } from 'styled-components'
import { applyTextStyle } from './typography'

const reset = createGlobalStyle`
  p {
    ${applyTextStyle('body')};
  }

  h1 {
    ${applyTextStyle('heading1')};
  }

  h2 {
    ${applyTextStyle('heading2')};
  }

  h3 {
    ${applyTextStyle('heading3')};
  }

  h4 {
    ${applyTextStyle('heading4')};
  }

  h5 {
    ${applyTextStyle('heading5')};
  }

  h6 {
    ${applyTextStyle('heading6')};
  }
`

export default reset
