import { css } from 'styled-components'
import {
  ITextRules,
  ITextStyle,
  TextStyleType,
  FontWeight,
  FontFamily,
  FontStyle,
  TextTransformType,
} from '@monorepo/shared/common/models/typography'
import { Breakpoint } from '@monorepo/shared/styles/responsive'
import { applyResponsive } from './responsive'

/**
 * Configure the text style presets
 */
export const textStyles = {
  headline1: {
    mobile: {
      fontSize: 74,
      lineHeight: 78,
      fontFamily: FontFamily.Love,
      fontWeight: FontWeight.Regular,
    },
    desktop: {
      fontSize: 60,
      lineHeight: 66,
      fontFamily: FontFamily.Crimson,
      fontWeight: FontWeight.Regular,
    },
  },
  headline2: {
    mobile: {
      fontSize: 40,
      lineHeight: 60,
      fontFamily: FontFamily.Love,
      fontWeight: FontWeight.Regular,
    },
    desktop: {
      fontSize: 100,
      lineHeight: 105,
      fontFamily: FontFamily.Love,
      fontWeight: FontWeight.Regular,
    },
  },
  heading1: {
    mobile: {
      fontSize: 26,
      lineHeight: 40,
      fontFamily: FontFamily.Crimson,
      fontWeight: FontWeight.Regular,
    },
    desktop: {
      fontSize: 60,
      lineHeight: 114,
      fontFamily: FontFamily.Love,
      fontWeight: FontWeight.Regular,
    },
  },
  heading2: {
    mobile: {
      fontSize: 24,
      lineHeight: 36,
      fontWeight: FontWeight.SemiBold,
      fontFamily: FontFamily.Crimson,
    },
    desktop: {
      fontSize: 50,
      lineHeight: 60,
      fontFamily: FontFamily.Crimson,
      fontWeight: FontWeight.Regular,
    },
  },
  heading3: {
    mobile: {
      fontSize: 20,
      lineHeight: 24,
      fontFamily: FontFamily.Crimson,
      fontWeight: FontWeight.Regular,
    },
    desktop: {
      fontSize: 32,
      lineHeight: 40,
      fontFamily: FontFamily.Crimson,
      fontWeight: FontWeight.Regular,
    },
  },
  heading4: {
    mobile: {
      fontSize: 18,
      lineHeight: 24,
      fontWeight: FontWeight.SemiBold,
      fontFamily: FontFamily.SFPro,
    },
    desktop: {
      fontSize: 24,
      lineHeight: 28,
      fontWeight: FontWeight.SemiBold,
      fontFamily: FontFamily.SFPro,
    },
  },
  heading5: {
    mobile: {
      fontSize: 14,
      lineHeight: 20,
      fontWeight: FontWeight.Bold,
      fontFamily: FontFamily.SFPro,
    },
    desktop: {
      fontSize: 24,
      lineHeight: 38,
      fontFamily: FontFamily.Crimson,
      fontWeight: FontWeight.Regular,
      letterSpacing: 0.02,
    },
  },
  heading6: {
    mobile: {
      fontSize: 16,
      lineHeight: 20,
      fontStyle: FontStyle.Italic,
      fontFamily: FontFamily.Crimson,
      fontWeight: FontWeight.Regular,
      letterSpacing: 0.02,
    },
    desktop: {
      fontSize: 24,
      lineHeight: 30,
      fontStyle: FontStyle.Italic,
      fontFamily: FontFamily.Crimson,
      fontWeight: FontWeight.Regular,
      letterSpacing: 0.02,
    },
  },
  body: {
    mobile: {
      fontSize: 14,
      lineHeight: 20,
      fontWeight: FontWeight.Medium,
      fontFamily: FontFamily.SFPro,
      letterSpacing: 0.02,
    },
    desktop: {
      fontSize: 22,
      lineHeight: 30,
      fontWeight: FontWeight.Medium,
      fontFamily: FontFamily.SFPro,
      letterSpacing: 0.02,
    },
  },
  link: {
    mobile: {
      fontSize: 14,
      lineHeight: 20,
      fontWeight: FontWeight.Bold,
      fontFamily: FontFamily.SFPro,
      textDecoration: 'underline',
      letterSpacing: 0.05,
    },
    desktop: {
      fontSize: 24,
      lineHeight: 36,
      fontWeight: FontWeight.Bold,
      fontFamily: FontFamily.SFPro,
      textDecoration: 'underline',
      letterSpacing: 0.02,
    },
  },
  smallText: {
    mobile: {
      fontSize: 12,
      lineHeight: 16,
      fontWeight: FontWeight.Medium,
      fontFamily: FontFamily.SFPro,
      letterSpacing: 0.01,
    },
    desktop: {
      fontSize: 18,
      lineHeight: 24,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.SFPro,
      letterSpacing: 0.01,
    },
  },
  /**
   * Common
   */
  label: {
    mobile: {
      fontSize: 9,
      lineHeight: 20,
      fontWeight: FontWeight.Bold,
      fontFamily: FontFamily.SFPro,
      letterSpacing: 0.2,
      textTransform: TextTransformType.Uppercase,
    },
    desktop: {
      fontSize: 11,
      lineHeight: 20,
      fontWeight: FontWeight.Bold,
      fontFamily: FontFamily.SFPro,
      letterSpacing: 0.2,
      textTransform: TextTransformType.Uppercase,
    },
  },
  label2: {
    mobile: {
      fontSize: 12,
      lineHeight: 20,
      fontWeight: FontWeight.Bold,
      fontFamily: FontFamily.SFPro,
      letterSpacing: 0.2,
      textTransform: TextTransformType.Uppercase,
    },
    desktop: {
      fontSize: 12,
      lineHeight: 20,
      fontWeight: FontWeight.Bold,
      fontFamily: FontFamily.SFPro,
      letterSpacing: 0.2,
      textTransform: TextTransformType.Uppercase,
    },
  },
  button: {
    mobile: {
      fontSize: 14,
      lineHeight: 20,
      fontWeight: FontWeight.SemiBold,
      fontFamily: FontFamily.SFPro,
      letterSpacing: 0.04,
    },
    desktop: {
      fontSize: 14,
      lineHeight: 20,
      fontWeight: FontWeight.SemiBold,
      fontFamily: FontFamily.SFPro,
      letterSpacing: 0.04,
    },
  },
  storyText: {
    mobile: {
      fontSize: 18,
      lineHeight: 26,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.SFPro,
      letterSpacing: 0.02,
    },
    desktop: {
      fontSize: 18,
      lineHeight: 26,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.SFPro,
      letterSpacing: 0.02,
    },
  },
  storyParagraph: {
    mobile: {
      fontSize: 28,
      lineHeight: 32,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.Crimson,
      letterSpacing: 0.02,
    },
    desktop: {
      fontSize: 24,
      lineHeight: 30,
      fontWeight: FontWeight.Regular,
      fontFamily: FontFamily.Crimson,
      letterSpacing: 0.02,
    },
  },
  storyCount: {
    mobile: {
      fontSize: 14,
      lineHeight: 16,
      fontStyle: FontStyle.Italic,
      fontFamily: FontFamily.Crimson,
      fontWeight: FontWeight.Regular,
    },
    desktop: {
      fontSize: 24,
      lineHeight: 30,
      fontStyle: FontStyle.Italic,
      fontFamily: FontFamily.Crimson,
      fontWeight: FontWeight.Regular,
    },
  },
}

export const defaultTextStyle: TextStyleType = 'body'

/**
 * Define which breakpoint to use to toggle between desktop/mobile
 * text styles
 */
export const defaultTypographyBreakpoint = Breakpoint.L

/**
 * Create the needed CSS for a given text style (works like a mixin)
 * @param textStyle The ITextStyle object, containing desktop styles
 * and optionally, mobile ones
 */
const applyCustomTextStyle = (
  textStyle: ITextStyle,
  forceStyle?: 'mobile' | 'desktop'
) => {
  const breakpoint = textStyle.breakpoint || defaultTypographyBreakpoint

  if (forceStyle === 'mobile') {
    return css`
      ${getTextRules(textStyle.mobile)}
    `
  }
  if (forceStyle === 'desktop') {
    return css`
      ${textStyle.desktop &&
      applyResponsive(
        { from: breakpoint },
        getTextRules(textStyle.desktop, 0.8)
      )}
    `
  }
  return css`
    ${getTextRules(textStyle.mobile)}
    ${textStyle.desktop &&
    applyResponsive({ from: breakpoint }, getTextRules(textStyle.desktop, 0.8))}
  `
}

/**
 * Return a FlattenSimpleInterpolation css`` literal for given
 * text rules
 * @param rules The ITextRules object
 */
const getTextRules = (rules: ITextRules, scaleDown?: number) => {
  return css`
    font-size: ${rules.fontSize * (scaleDown ?? 1)}px;
    line-height: ${rules.lineHeight * (scaleDown ?? 1)}px;
    ${rules.fontFamily !== undefined &&
    css`
      font-family: ${rules.fontFamily};
    `}
    ${rules.fontWeight !== undefined &&
    css`
      font-weight: ${rules.fontWeight};
    `}
    ${rules.textTransform !== undefined &&
    css`
      text-transform: ${rules.textTransform};
    `}
    ${rules.fontStyle !== undefined &&
    css`
      font-style: ${rules.fontStyle};
    `}
    ${rules.textDecoration !== undefined &&
    css`
      text-decoration: ${rules.textDecoration};
    `}
    ${rules.letterSpacing !== undefined &&
    css`
      letter-spacing: ${rules.letterSpacing}em;
    `}
  `
}

/**
 * Apply a predefined text style (works like applyCustomTextStyle, but
 * takes as input a preset name, instead of an ITextRules object)
 * @param name The text style key, eg. 'caption' or 'labelHeavy'
 */
const applyTextStyle = (
  name: TextStyleType,
  forceStyle?: 'mobile' | 'desktop'
) => {
  const textStyle = textStyles[name]
  return applyCustomTextStyle(textStyle, forceStyle)
}

/**
 * Apply ellipsis-style text overflow
 */
const applyTextEllipsis = () => {
  return css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `
}

/**
 * Remove text ellipsis style (unset certain properties)
 */
const removeTextEllipsis = () => {
  return css`
    text-overflow: unset;
    white-space: unset;
    overflow: unset;
  `
}

export {
  Breakpoint,
  applyCustomTextStyle,
  applyTextStyle,
  applyTextEllipsis,
  removeTextEllipsis,
}
