import Cookies from 'universal-cookie/es6'

export const getCookie = (name: string): string | undefined => {
  const cookies = new Cookies()
  return cookies.get(name)
}

export const deleteCookie = (name: string) => {
  const cookies = new Cookies()
  cookies.remove(name, { path: '/' })
  cookies.remove(name, {
    path: '/',
    domain: process.env.GATSBY_NETLIFY ? 'rouze.me' : undefined,
  })
  cookies.remove(name, {
    path: '/',
    domain: process.env.GATSBY_NETLIFY ? 'app.rouze.me' : undefined,
  })
}

export const createCookie = (key: string, value: string) => {
  const cookies = new Cookies()
  cookies.set(key, value, {
    path: '/',
    maxAge: 60 * 60 * 24 * 30,
    domain: process.env.GATSBY_NETLIFY ? 'rouze.me' : undefined,
  })
}

export const createAuthCookie = (value: string) =>
  createCookie('auth_user_token_v2', value)
export const deleteAuthCookie = () => {
  deleteCookie('auth_user_token_v2')
  const logoutEvent = new Event('logout')
  document.dispatchEvent(logoutEvent)
}
export const getAuthCookie = () => getCookie('auth_user_token_v2')
