import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrowDown = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3.5735 6.64818C3.93148 6.2902 4.51188 6.2902 4.86986 6.64818L13.9444 15.7227C14.3024 16.0807 14.3024 16.6611 13.9444 17.0191L12.648 18.3154C12.2901 18.6734 11.7097 18.6734 11.3517 18.3154L2.27714 9.24091C1.91916 8.88293 1.91916 8.30252 2.27714 7.94454L3.5735 6.64818Z"
      fill={props.color}
    />
    <path
      d="M10.0964 17.0193C9.73844 16.6613 9.73844 16.0809 10.0964 15.7229L19.171 6.64838C19.5289 6.2904 20.1093 6.2904 20.4673 6.64838L21.7637 7.94474C22.1217 8.30272 22.1217 8.88312 21.7637 9.2411L12.6891 18.3156C12.3312 18.6736 11.7508 18.6736 11.3928 18.3156L10.0964 17.0193Z"
      fill={props.color}
    />
  </svg>
)

export default IconArrowDown
