import React from 'react'
import colors from '@monorepo/shared/styles/colors'
import { IIconPropsMore as IProps } from './IIconProps'

const IconHamburger = (props: IProps) => (
  <svg
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 18 13"
    fill={props.color}
  >
    <path
      d="M0 12.7939H18V10.7889H0V12.7939ZM0 7.7813H18V5.77626H0V7.7813ZM0 0.763672V2.76871H18V0.763672H0Z"
      fill="white"
    />
  </svg>
)

IconHamburger.defaultProps = {
  color: colors.white,
}

export default IconHamburger
