/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import Helmet from 'react-helmet'

interface IMeta {
  name: string
  content: string
}
interface IProps {
  noIndex?: boolean
  description?: string
  imageUrl?: string
  lang?: string
  meta?: IMeta[]
  title: string
}

const Seo: React.FC<IProps> = ({
  noIndex,
  description = '',
  lang = 'en',
  meta = [],
  imageUrl,
  title,
}) => {
  const { site } = {
    site: {
      siteMetadata: {
        title: `Rouze`,
        description: `Luister hier naar de beste sexy verhalen en laat je meevoeren naar een andere wereld.`,
        author: `AuxNederland`,
        siteUrl: `https://app.rouze.me`,
      },
    },
  }

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const metaImage =
    imageUrl ??
    'https://images.prismic.io/rouz/ad01bcbe-53bc-40bd-bfcd-912ad025aca2_Rouze+image.jpg?auto=compress,format'

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'robots',
          content: noIndex ? 'noindex' : 'all',
        },
        {
          name: `title`,
          content: metaTitle,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          itemProp: `image`,
          content: metaImage,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: 'og:image:secure_url',
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
      ].concat(meta || [])}
    ></Helmet>
  )
}

export default Seo
