import React, { useState } from 'react'
import styled, { css } from 'styled-components'

// Styles
import { applyTextStyle } from '@monorepo/shared/styles/typography'
import { fastTransition } from '@monorepo/shared/styles/effects'

// Components
import { Color } from '@monorepo/shared/common/models/colors'
import { Icon } from '@monorepo/shared/components/icon/'

import colors from '@monorepo/shared/styles/colors'
import { IconType } from '@monorepo/shared/components/icon/IconType'
import { Link } from 'gatsby'

// Types

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

export type ButtonVariantType = 'primary' | 'secondary'
export type ButtonType = 'button' | 'submit'

export interface IProps {
  disabled?: boolean
  caption?: string | React.ReactNode
  hoverCaption?: string | React.ReactNode
  icon?: IconType
  variant?: ButtonVariantType
  color?: Color
  textColor?: Color
  hoverColor?: Color
  hoverTextColor?: Color
  className?: string
  withUnderline?: boolean
  iconSize?: number
  type?: ButtonType
  onClick?: (event?: React.MouseEvent) => void
  role?: React.AriaRole
  iconColor?: string
  mode?: 'button' | 'link' // Default is button
  internalLink?: string
  externalLink?: string
  target?: React.HTMLAttributeAnchorTarget
}

const Button = (props: IProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const variant = props.variant ? props.variant : ButtonVariant.Primary

  const caption = props.caption ?? ''
  const hoverCaption = props.hoverCaption ?? caption
  const color: Color = props.color
    ? props.color
    : variant === ButtonVariant.Primary
    ? 'transparent'
    : 'white'
  const textColor: Color = props.textColor
    ? props.textColor
    : variant === ButtonVariant.Primary
    ? 'white'
    : 'black'
  const hoverColor: Color = props.hoverColor ?? 'whiteTransparent'
  const hoverTextColor: Color = props.hoverTextColor || 'white'
  const iconSize = 14
  const iconColor = props.iconColor ?? colors.white
  const onlyIcon = !props.caption && !!props.icon
  const internalLink = props.internalLink
  const externalLink = props.externalLink

  return props.mode === 'link' ? (
    internalLink ? (
      <StyledLink
        to={internalLink}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={props.onClick}
        className={props.className}
        color={color}
        variant={variant}
        textColor={textColor}
        hoverColor={hoverColor}
        hoverTextColor={hoverTextColor}
        onlyIcon={onlyIcon}
        role={props.role}
      >
        {props.icon !== undefined && (
          <Icon size={iconSize} color={iconColor} type={props.icon} />
        )}
        {!!caption && (
          <ButtonLabel>{isHovered ? hoverCaption : caption}</ButtonLabel>
        )}
      </StyledLink>
    ) : (
      <StyledExternalLink
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={props.onClick}
        className={props.className}
        href={externalLink}
        target={props.target}
        color={color}
        variant={variant}
        textColor={textColor}
        hoverColor={hoverColor}
        hoverTextColor={hoverTextColor}
        onlyIcon={onlyIcon}
        role={props.role}
      >
        {props.icon !== undefined && (
          <Icon size={iconSize} color={iconColor} type={props.icon} />
        )}
        {!!caption && (
          <ButtonLabel>{isHovered ? hoverCaption : caption}</ButtonLabel>
        )}
      </StyledExternalLink>
    )
  ) : (
    <StyledButton
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={props.onClick}
      type={props.type !== undefined ? props.type : 'button'}
      className={props.className}
      disabled={props.disabled}
      color={color}
      variant={variant}
      textColor={textColor}
      hoverColor={hoverColor}
      hoverTextColor={hoverTextColor}
      onlyIcon={onlyIcon}
      role={props.role}
    >
      {props.icon !== undefined && (
        <Icon size={iconSize} color={iconColor} type={props.icon} />
      )}
      {!!caption && (
        <ButtonLabel>{isHovered ? hoverCaption : caption}</ButtonLabel>
      )}
    </StyledButton>
  )
}

const buttonStyling = css<
  Pick<
    IProps,
    | 'variant'
    | 'color'
    | 'withUnderline'
    | 'textColor'
    | 'hoverColor'
    | 'hoverTextColor'
  > & { onlyIcon: boolean }
>`
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 10px 30px;
  outline: none;
  position: relative;
  text-decoration: ${(props) => (props.withUnderline ? 'underline' : 'none')};
  cursor: pointer;
  transition: color ${fastTransition}, background-color ${fastTransition};
  outline: none;

  &:disabled {
    cursor: default;
    pointer-events: none;
    label {
      cursor: default;
    }
  }
  ${(props) =>
    css`
      background-color: ${colors[props.color!]};
      color: ${colors[props.textColor!]};
      &:hover {
        color: ${colors[props.hoverTextColor!]};
        background-color: ${colors[props.hoverColor!]};
      }
    `}

  ${(props) =>
    props.variant === ButtonVariant.Primary &&
    css`
      border-radius: ${props.onlyIcon ? '100%' : '30px'};
      border: 2px solid ${colors.white};

      &:hover {
        svg > path {
          fill: ${colors[props.hoverTextColor!]};
        }
      }
      &:disabled {
        color: ${colors.whiteTransparent};
        border: 2px solid ${colors.whiteTransparent};
        svg > path {
          fill: ${colors.whiteTransparent};
        }
      }
    `}
  ${(props) =>
    props.variant === ButtonVariant.Secondary &&
    css`
      border-radius: 40px;
      border: 0;

      &:hover {
        svg > path {
          fill: ${colors[props.hoverColor!]};
        }
      }
      &:disabled {
        background-color: ${colors.whiteTransparent};
        color: ${colors.whiteTransparent};
        svg > path {
          fill: ${colors.disabled};
        }
      }
    `}
    ${(props) =>
    props.onlyIcon &&
    css`
      width: 40px;
      height: 40px;
      padding: 0;
    `}
`

export const StyledButton = styled.button<
  Pick<
    IProps,
    | 'variant'
    | 'color'
    | 'withUnderline'
    | 'textColor'
    | 'hoverColor'
    | 'hoverTextColor'
  > & { onlyIcon: boolean }
>`
  ${buttonStyling};
`

const StyledLink = styled(Link)<
  Pick<
    IProps,
    | 'variant'
    | 'color'
    | 'withUnderline'
    | 'textColor'
    | 'hoverColor'
    | 'hoverTextColor'
  > & { onlyIcon: boolean }
>`
  ${buttonStyling};
`

const StyledExternalLink = styled.a<
  Pick<
    IProps,
    | 'variant'
    | 'color'
    | 'withUnderline'
    | 'textColor'
    | 'hoverColor'
    | 'hoverTextColor'
  > & { onlyIcon: boolean }
>`
  ${buttonStyling};
`

const ButtonLabel = styled.label`
  ${applyTextStyle('button', 'mobile')}
  cursor: pointer;
`

export default Button
