import { Color } from '@monorepo/shared/common/models/colors'

/**
 * Colors
 */
export default {
  primary1: '#6585FB',
  primary2: '#001E14',
  primary3: '#FDA18B',
  primary4: '#9BC2F8',
  neutralWhite: '#FFFFFF',
  neutralGray: '#BDBDBD',
  glassLight: ' rgba(255, 255, 255, 0.05)',
  glassMedium: 'rgba(28, 28, 28, 0.5)',
  glassDark: 'rgba(16, 16, 16, 0.9)',
  bkgdDisNonhovered: 'rgba(28, 28, 28, 0.5)',
  bkgdDisNonhoveredBlur: 'rgba(28, 28, 28, 0.5)',
  fdbkSpiciness: '#FB4822',
  fdbkSuccess: '#6DB95A',
  fdbkError: '#DD5E5E',
  whiteTransparent: 'rgba(255, 255, 255, 0.2);',
  whiteSemiTransparent: 'rgba(255, 255, 255, 0.45);',
  transparent: 'transparent',
  white: '#FFFFFF',
  black: '#000000',
  disabled: '#979797',
  primaryLight: '#FFAA33',
  red: '#DD5E5E',
  blue: '#3875EA',
  darkBlue: '#2A60C8',
  heartActive: '#FF1200',
  heartDisabled: 'rgba(255, 255, 255, 0.5)',
}

/**
 * Some components allow color customization. Since that's
 * optional, use this as a default fallback color in case
 * it's unset
 */
export const defaultColor: Color = 'primary1'

export const defaultTextColor: Color = 'primary2'
