import { Breakpoint } from '@monorepo/shared/styles/responsive'
import { textStyles } from '../../../styles/typography'

/**
 * Define the possible values for CSS 'font-weight' property and give them
 * names
 */
export enum FontWeight {
  Thin = 100,
  ExtraLight = 200,
  Light = 300,
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
  ExtraBold = 800,
  Heavy = 900,
}

/**
 * Define the possible values for CSS 'font-family' property and give them names
 */
export enum FontFamily {
  Crimson = "'Crimson Text', serif;",
  Love = "'Love', serif;",
  SFPro = "'SF Pro Display', sans-serif;",
}

/**
 * Define the possible values for CSS 'font-style' property and give them names
 */
export enum FontStyle {
  Italic = 'italic',
  Bold = 'bold',
  Normal = 'normal',
}

/**
 * Define CSS rules that generally apply to text elements
 */
export interface ITextRules {
  fontSize: number // In px
  lineHeight: number // In px
  fontFamily?: FontFamily
  fontWeight?: FontWeight
  textTransform?: TextTransformType
  fontStyle?: FontStyle
  textDecoration?: string
  color?: string
  letterSpacing?: number // In em
}

/**
 * Define mobile text rules and optionally, desktop ones
 */
export interface ITextStyle {
  mobile: ITextRules
  desktop?: ITextRules
  breakpoint?: Breakpoint
}

export type TextStyleType = keyof typeof textStyles

export type TextTagType =
  | 'div'
  | 'p'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'strong'
  | 'em'
  | 'span'
  | 'label'
  | 'link'

/**
 * Define the possible values for the CSS 'text-transform' property
 */
export enum TextTransformType {
  Lowercase = 'lowercase',
  Uppercase = 'uppercase',
  Capitalize = 'capitalize',
}
