import React, { useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import styled from 'styled-components'
import colors from '@monorepo/shared/styles/colors'
import { applyTextStyle } from '@monorepo/shared/styles/typography'
import { useLocation } from '@reach/router' // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { Link } from 'gatsby'

interface IProps {
  mode: 'site' | 'app'
}

const CookieBanner: React.FC<IProps> = ({ mode }) => {
  const buttonStyle = {
    display: 'block',
    margin: '0px',
    background: colors.neutralWhite,
    textDecoration: 'underline',
    fontSize: '13px',
    lineHeight: '20px',
    fontWeight: '700',
    letterSpacing: '0.05em',
    fontFamily: 'SF Pro Display',
    borderLeft: `1px solid ${colors.primary2}`,
    marginLeft: '16px',
    color: 'black',
  }

  const getWebsiteLink = (url: string, text: string) => {
    return mode === 'site' ? (
      <Link to={url}>{text}</Link>
    ) : (
      <a href={`${process.env.GATSBY_WEBSITE_URL}${url}`}>{text}</a>
    )
  }

  const declineButtonStyle = {
    ...buttonStyle,
    fontSize: '11px',
  }

  const contentStyle = { color: colors.primary2, margin: '0', flex: '1 0' }

  const style = {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'no-wrap',
    background: colors.neutralWhite,
    fontFamily: 'SF Pro Display',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '500',
    letterSpacing: '0.02em',
    padding: '17px',
    borderRadius: '4px',
    marginBottom: '23px',
    left: '50%',
    width: '90%',
    marginLeft: '-45%',
  }

  const location = useLocation()

  const [settingsVisible, setSettingsVisible] = useState<boolean>(false)

  return (
    <>
      <CookieConsent
        expires={365}
        style={style}
        contentStyle={contentStyle}
        buttonStyle={buttonStyle}
        buttonText="Sluiten"
        declineButtonText="Instellingen"
        declineButtonStyle={declineButtonStyle}
        enableDeclineButton
        setDeclineCookie={false}
        flipButtons={true}
        onAccept={() => {
          initializeAndTrack(location)
        }}
        onDecline={() => {
          setSettingsVisible(true)
        }}
      >
        Door dit bericht te sluiten, volg je ons cookie beleid en sta je Rouze
        toe cookies te gebruiken op dit apparaat{' '}
        <PolicyLink>{getWebsiteLink('/privacy', 'cookie policy')}</PolicyLink>.
      </CookieConsent>
      {settingsVisible && (
        <CookieConsent
          expires={365}
          style={style}
          contentStyle={contentStyle}
          buttonStyle={buttonStyle}
          buttonText="accepteer alles"
          declineButtonText="alles afwijzen"
          declineButtonStyle={declineButtonStyle}
          onAccept={() => {
            initializeAndTrack(location)
          }}
          enableDeclineButton
          flipButtons={true}
        >
          Door dit bericht te sluiten, volg je ons cookie beleid en sta je Rouze
          toe cookies te gebruiken op dit apparaat{' '}
          <PolicyLink>{getWebsiteLink('/privacy', 'cookie policy')}</PolicyLink>
          .
        </CookieConsent>
      )}
    </>
  )
}

const PolicyLink = styled.a`
  ${applyTextStyle('body', 'mobile')}
  color: ${colors.primary2};
  text-decoration: underline;

  * {
    color: ${colors.primary2} !important;
  }
`

export default CookieBanner
