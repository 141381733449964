import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconSearch = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M13 6.5C13 10.0899 10.0899 13 6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5Z"
      fill={props.color}
      fillOpacity="0.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 11C8.98528 11 11 8.98528 11 6.5C11 4.01472 8.98528 2 6.5 2C4.01472 2 2 4.01472 2 6.5C2 8.98528 4.01472 11 6.5 11ZM6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13Z"
      fill={props.color}
    />
    <path
      d="M10.0605 9.35355C10.2558 9.15829 10.5724 9.15829 10.7676 9.35355L14.3031 12.8891C14.4984 13.0843 14.4984 13.4009 14.3031 13.5962L13.596 14.3033C13.4008 14.4986 13.0842 14.4986 12.8889 14.3033L9.3534 10.7678C9.15814 10.5725 9.15814 10.2559 9.3534 10.0607L10.0605 9.35355Z"
      fill={props.color}
    />
  </svg>
)

export default IconSearch
