/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'

// Components
import CookieBanner from '../shared/components/CookieBanner'

// Styling
import CSSReset from '../shared/styles/reset'
import { ContextTemplate } from './src/context/AppContext'
// Setup

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import Footer from './src/components/Footer'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY || '')

/* eslint-disable react/prop-types */
/**
 * Styles do not need to be re-rendered and thus sit together with Providers
 * and other top-level components
 *
 * For more details, see: https://www.gatsbyjs.org/docs/browser-apis/#wrapRootElement
 */
export const wrapRootElement = ({ element }) => {
  return (
    <Elements stripe={stripePromise}>
      <CSSReset />
      <ContextTemplate>{element}</ContextTemplate>
    </Elements>
  )
}

/**
 * Set up persistent UI elements that are not unmounted on page change
 *
 * For more details, see: https://www.gatsbyjs.org/docs/browser-apis/#wrapPageElement
 */
export const wrapPageElement = ({ element }) => (
  <>
    {element}
    <CookieBanner />
    <Footer mode={'app'} />
  </>
)
