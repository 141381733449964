import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconFastForward = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 20 22"
    fill="none"
  >
    <path
      d="M8.1243 14.5H8.7659V10.2725H8.11844L7.00516 11.0635V11.6846L8.10965 10.9229H8.1243V14.5ZM11.4011 14.5703C12.4089 14.5703 13.0154 13.7295 13.0154 12.3877V12.3818C13.0154 11.04 12.4089 10.2021 11.4011 10.2021C10.3904 10.2021 9.78684 11.04 9.78684 12.3818V12.3877C9.78684 13.7295 10.3904 14.5703 11.4011 14.5703ZM11.4011 14.0225C10.7976 14.0225 10.4489 13.4014 10.4489 12.3877V12.3818C10.4489 11.3682 10.7976 10.7529 11.4011 10.7529C12.0017 10.7529 12.3532 11.3682 12.3532 12.3818V12.3877C12.3532 13.4014 12.0017 14.0225 11.4011 14.0225Z"
      fill={props.color}
    />
    <path
      d="M19.5 12C19.5 11.7239 19.2761 11.5 19 11.5C18.7239 11.5 18.5 11.7239 18.5 12H19.5ZM13.825 3.85091L14.0378 3.39843L13.825 3.85091ZM16.75 6.0469L14.4847 0.736384L11.0183 5.35349L16.75 6.0469ZM18.5 12C18.5 16.6944 14.6944 20.5 10 20.5V21.5C15.2467 21.5 19.5 17.2467 19.5 12H18.5ZM10 20.5C5.30558 20.5 1.5 16.6944 1.5 12H0.5C0.5 17.2467 4.75329 21.5 10 21.5V20.5ZM1.5 12C1.5 7.30558 5.30558 3.5 10 3.5V2.5C4.75329 2.5 0.5 6.75329 0.5 12H1.5ZM10 3.5C11.2926 3.5 12.5166 3.7882 13.6122 4.30338L14.0378 3.39843C12.8117 2.82196 11.4428 2.5 10 2.5V3.5Z"
      fill={props.color}
    />
  </svg>
)

export default IconFastForward
