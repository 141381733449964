import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconPreviousEpisode = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 12 14"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6.05265L10.47 0.758898C11.136 0.342617 12 0.82146 12 1.6069L12 12.9984C12 13.7838 11.136 14.2627 10.47 13.8464L2 8.55265L2 12.8026C2 13.3549 1.55229 13.8026 1 13.8026C0.447715 13.8026 -5.63935e-08 13.3549 -1.04676e-07 12.8026L-1.06633e-06 1.80265C-1.11461e-06 1.25036 0.447714 0.80265 0.999999 0.80265C1.55228 0.802649 2 1.25037 2 1.80265L2 6.05265Z"
      fill={props.color}
    />
  </svg>
)

export default IconPreviousEpisode
