import React from 'react'
import colors from '@monorepo/shared/styles/colors'
import { IIconPropsMore as IProps } from './IIconProps'

const IconHeartEmpty = (props: IProps) => (
  <svg
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    version="1.1"
    id="Livello_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 20 19"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill={props.color}
        d="M15,2c1.3,0,2.3,0.7,2.6,1.4c0.3,0.6,1.2,2.5-1.3,6c-1.4,1.9-3.4,3.9-6.3,6.2c-2.9-2.3-4.9-4.3-6.3-6.3
		C1.2,6,2.1,4.1,2.4,3.4C2.8,2.7,3.7,2,5,2c0.4,0,0.7,0.1,1.1,0.2C7,2.4,7.8,3,8.4,3.8L10,6.1l1.6-2.3C12.2,3,13,2.4,13.9,2.2
		C14.2,2.1,14.6,2,15,2 M15,0c-0.5,0-1.1,0.1-1.6,0.2C12,0.6,10.8,1.5,10,2.6c-0.8-1.1-2-2-3.3-2.4C6.1,0.1,5.6,0,5,0
		C3,0,1.3,1.2,0.6,2.5c-1.2,2.4-0.7,5.1,1.5,8c1.7,2.3,4.1,4.6,7.5,7.3C9.7,18,9.9,18,10,18c0.1,0,0.3,0,0.4-0.1
		c3.5-2.7,5.9-5,7.5-7.3c2.1-2.9,2.6-5.6,1.4-8C18.7,1.2,17,0,15,0L15,0z"
      />
    </g>
  </svg>
)
IconHeartEmpty.defaultProps = {
  color: colors.white,
}

export default IconHeartEmpty
